import { useState } from "react";

import { useForm } from "react-hook-form";
import { mutateCollectionValidateSchema } from "src/types/form-validations";
import { CollectionForm } from "src/types/forms";
import { ProductResponse } from "src/types/services";

import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import ClearIcon from "@mui/icons-material/Clear";
import { Box, Button, Grid, IconButton, Typography } from "@mui/material";
import ProductSelectionDialog from "src/components/dialogs/ProductSelectionDialog";
import ImageInputSection from "src/components/ImageInput";
import InputLabel from "src/components/InputLabel";
import TextFieldHF from "src/components/rhf/TextFieldHF";

import ActionButtonSection from "./ActionButtonSection";
import ConfirmedDialog from "./dialogs/ConfirmedDialog";
import PageWrapper from "./PageWrapper";
import { PaperContentSection } from "./PaperContentSection";

import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { joiResolver } from "@hookform/resolvers/joi";
import useCollectionDetail from "src/hooks/useCollectionDetail";
import useProducts from "src/hooks/useProducts";
import useShowOverlayLoading from "src/hooks/useShowOverlayLoading";
import useUploadImage from "src/hooks/useUploadImage";

const CollectionFormSection = ({
    collectionId,
    onSave,
    onDelete,
}: {
    collectionId?: string;
    onSave: (data: CollectionForm) => void;
    onDelete?: () => void;
}) => {
    const showLoading = useShowOverlayLoading();

    const { handleSubmit, control, setValue, reset } = useForm<CollectionForm>({
        defaultValues: {
            title: "",
            product_ids: [],
        },
        resolver: joiResolver(mutateCollectionValidateSchema),
    });

    const { collectionDetail } = useCollectionDetail({
        id: collectionId,
        onSuccess: (data) => {
            reset({
                title: data.title,
                description: data.description,
                image_url: data.image?.url,
            });
        },
    });

    const { mutateAsync: onAsyncUploadImage } = useUploadImage();
    const [image, setImage] = useState<any>();

    const onSubmit = async (data: CollectionForm) => {
        showLoading(true);

        const dataSave: CollectionForm = {
            ...data,
            product_ids: productsSelected.map((p) => p.id),
        };

        if (!image) {
            onSave({ ...dataSave });
        } else {
            await onAsyncUploadImage(image).then((respUploadImage) => {
                onSave({ ...dataSave, image_url: respUploadImage.data.url });
            });
        }
    };

    const [idCollectionInCallProducts, setIdCollectionInCallProducts] = useState<string>(
        collectionId || ""
    );

    const { products, pagination, onSearch, onReset } = useProducts({
        idCollection: idCollectionInCallProducts,
        onSuccess: (data) => {
            if (idCollectionInCallProducts) {
                setProductsSelected(data.items);
                setIdCollectionInCallProducts("");
            }
        },
    });

    const [productsSelected, setProductsSelected] = useState<ProductResponse[]>([]);

    const handleProductSelection = (checked: boolean, ...selectedProducts: ProductResponse[]) => {
        if (!selectedProducts.length) return;

        switch (checked) {
            case true:
                setProductsSelected((preValue) => [...preValue, ...selectedProducts]);
                break;

            default:
                selectedProducts.forEach(({ id }) => {
                    setProductsSelected((preValue) => [...preValue.filter((p) => p.id !== id)]);
                });
                break;
        }
    };

    const [isShowProductSelectionDialog, setIsShowProductSelectionDialog] =
        useState<boolean>(false);

    const [isShowConfirmedDialog, setIsShowConfirmedDialog] = useState<boolean>(false);
    const handleCollectionDeleteConfirmedDialog = (isAccept: boolean) => {
        setIsShowConfirmedDialog(false);

        if (isAccept) {
            onDelete && onDelete();
        }
    };

    return (
        <PageWrapper
            title={collectionId ? "Cập nhật nhóm sản phẩm" : "Tạo nhóm sản phẩm"}
            actionArea={
                <ActionButtonSection actionOnClick={handleSubmit(onSubmit)} backTo="/collections" />
            }>
            <Grid container spacing={3}>
                <Grid item md={12} lg={8}>
                    <PaperContentSection title="Thông tin chung" topSection>
                        <Grid container spacing={2} p={2}>
                            <Grid item xs={12}>
                                <TextFieldHF
                                    control={control}
                                    name="title"
                                    placeholder="Ví dụ: Nhóm Apple"
                                    labelOverride="Tên nhóm sản phẩm"
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <InputLabel>Mô tả nhóm sản phẩm</InputLabel>
                                <CKEditor
                                    config={{
                                        language: "vi",
                                    }}
                                    data={collectionDetail?.description}
                                    editor={ClassicEditor}
                                    onChange={(_, editor) => {
                                        const data = editor.getData();
                                        setValue("description", data);
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </PaperContentSection>
                    <PaperContentSection
                        title="Sản phẩm"
                        action={{
                            onAction: () => setIsShowProductSelectionDialog(true),
                            icon: <AddCircleOutlinedIcon />,
                            title: "Thêm Sản Phẩm",
                        }}>
                        <Grid container spacing={2} p={2}>
                            <Grid item xs={12}>
                                {productsSelected.length === 0
                                    ? "Chưa có sản phẩm"
                                    : productsSelected.map((productSelected) => (
                                          <Box
                                              display={"flex"}
                                              alignItems={"center"}
                                              key={productSelected.id}
                                              sx={{
                                                  paddingBottom: 1,
                                                  paddingTop: 1,
                                                  borderBottom: "1px solid #dedede",
                                              }}>
                                              <Box className="table-cell--image">
                                                  {productSelected.images ? (
                                                      <img
                                                          className="box-image"
                                                          src={productSelected.images[0].url}
                                                          alt={productSelected.images[0].alt}
                                                      />
                                                  ) : (
                                                      <img
                                                          className="box-image"
                                                          src="/images/nothing-image.png"
                                                          alt=""
                                                      />
                                                  )}
                                              </Box>
                                              <Typography variant="body2" flexGrow={1} ml={1}>
                                                  {productSelected.name}
                                              </Typography>
                                              <IconButton
                                                  aria-label="clear"
                                                  onClick={(_) =>
                                                      handleProductSelection(false, productSelected)
                                                  }>
                                                  <ClearIcon />
                                              </IconButton>
                                          </Box>
                                      ))}
                            </Grid>
                        </Grid>
                    </PaperContentSection>
                </Grid>
                <Grid item md={12} lg={4}>
                    <PaperContentSection title="Hình đại diện" topSection>
                        <ImageInputSection
                            setImage={setImage}
                            defaultSrc={collectionDetail?.image?.url}
                        />
                    </PaperContentSection>
                </Grid>
                {collectionDetail ? (
                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            color="error"
                            onClick={() => setIsShowConfirmedDialog(true)}>
                            Xóa
                        </Button>
                    </Grid>
                ) : null}
            </Grid>
            <ProductSelectionDialog
                open={isShowProductSelectionDialog}
                products={products}
                selected={productsSelected}
                onSearch={onSearch}
                pagination={pagination}
                onSelected={handleProductSelection}
                onClose={() => {
                    setIsShowProductSelectionDialog(false);
                    onReset();
                }}
            />
            <ConfirmedDialog
                show={isShowConfirmedDialog}
                onClose={handleCollectionDeleteConfirmedDialog}
            />
        </PageWrapper>
    );
};

export default CollectionFormSection;
