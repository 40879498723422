import { useMemo } from "react";

import { QueryDataResponse, VendorResponse } from "src/types/services";
import { getData } from "src/util/service";

import { useQuery } from "@tanstack/react-query";

const useVendors = () => {
    const { data: dataVendors } = useQuery<QueryDataResponse<VendorResponse, false>>({
        staleTime: Infinity,
        cacheTime: 0,
        queryKey: ["vendors"],
        queryFn: () => getData({ path: "/vendors" }),
    });

    return useMemo(() => dataVendors?.items ?? [], [dataVendors?.items]);
};

export default useVendors;
