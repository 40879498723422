import { useNavigate, useParams } from "react-router-dom";

import CollectionFormSection from "src/components/CollectionFormSection";

import useDeleteCollection from "src/hooks/useDeleteCollection";
import useShowOverlayLoading from "src/hooks/useShowOverlayLoading";
import useUpdateCollection from "src/hooks/useUpdateCollection";

const EditCollectionPage = () => {
    const navigate = useNavigate();
    const showLoading = useShowOverlayLoading();
    const { id: collectionId } = useParams();

    const { mutate: onDelete } = useDeleteCollection({
        onSuccess() {
            navigate("/collections");
        },
    });

    const { mutate: onUpdate } = useUpdateCollection({
        collectionId: collectionId || "",
        onSuccess: () => {
            showLoading(false);
            navigate("/collections");
        },
    });

    return (
        <CollectionFormSection
            collectionId={collectionId}
            onSave={onUpdate}
            onDelete={() => onDelete(collectionId || "")}
        />
    );
};

export default EditCollectionPage;
