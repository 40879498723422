import { PropsWithChildren } from "react";

import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import { Box, BoxProps, Divider, IconButton, Tooltip, Typography } from "@mui/material";

import { PaperCustom } from "./PaperCustom";

type PaperContentSectionProps = {
    title?: string;
    titleTooltip?: string;
    topSection?: boolean;
    action?: {
        onAction: () => void;
        title: string;
        icon: JSX.Element;
    };
} & Pick<BoxProps, "sx">;

export const PaperContentSection = ({
    children,
    title,
    titleTooltip,
    topSection = false,
    sx,
    action,
}: PropsWithChildren<PaperContentSectionProps>) => {
    return (
        <PaperCustom sx={sx} mt={topSection ? 0 : 3}>
            {title ? (
                <>
                    <Box
                        display={"flex"}
                        justifyContent={"space-between"}
                        textAlign={"center"}
                        alignItems={"center"}>
                        <Typography variant="body2" fontWeight={"bold"}>
                            {title}
                            {titleTooltip ? (
                                <Tooltip title={titleTooltip}>
                                    <HelpOutlineOutlinedIcon fontSize={"inherit"} color="primary" />
                                </Tooltip>
                            ) : null}
                        </Typography>
                        {action ? (
                            <Tooltip title={action.title} placement="top">
                                <IconButton
                                    color="primary"
                                    sx={{ padding: 0 }}
                                    onClick={action.onAction}>
                                    {action.icon}
                                </IconButton>
                            </Tooltip>
                        ) : null}
                    </Box>
                    <Divider
                        sx={{
                            margin: "8px 0",
                        }}
                    />
                </>
            ) : null}
            {children}
        </PaperCustom>
    );
};
