import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { Box } from "@mui/material";
import ButtonLink from "src/components/ButtonLink";
import PageWrapper from "src/components/PageWrapper";
import { PaperCustom } from "src/components/PaperCustom";
import ProductFilterContainer from "src/components/ProductFilterContainer";
import ProductTable from "src/components/tables/ProductTable";
import TablePaginationWrapper from "src/components/tables/TablePaginationWrapper";

import useProducts from "src/hooks/useProducts";

const ProductsPage = () => {
    const { products, pagination, onSearch, isLoading } = useProducts({});

    return (
        <PageWrapper
            title="Danh sách sản phẩm"
            actionArea={
                <>
                    <ButtonLink
                        to={"/collections/create"}
                        icon={<AddCircleOutlineIcon />}
                        sx={{
                            mr: 1,
                        }}>
                        Tạo Combo
                    </ButtonLink>
                    <ButtonLink to={"/products/create"} icon={<AddCircleOutlineIcon />}>
                        Tạo sản phẩm
                    </ButtonLink>
                </>
            }>
            <PaperCustom>
                <ProductFilterContainer onSearch={onSearch} />
                <ProductTable products={products} isLoading={isLoading} />
                <Box pt={1}>
                    <TablePaginationWrapper pagination={pagination} />
                </Box>
            </PaperCustom>
        </PageWrapper>
    );
};

export default ProductsPage;
