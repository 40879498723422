import { useState } from "react";

import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { Box, IconButton, InputAdornment, TextField } from "@mui/material";

const CollectionFilterContainer = ({ onSearch }: { onSearch: (keyword: string) => void }) => {
    const [searchValue, setSearchValue] = useState("");

    return (
        <Box display={"flex"} width={"100%"} mb={1} pt={1}>
            <TextField
                value={searchValue}
                onChange={(event) => {
                    setSearchValue(event.target.value);
                    onSearch(event.target.value);
                }}
                size="small"
                placeholder="Tìm kiếm"
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchOutlinedIcon fontSize="small" />
                        </InputAdornment>
                    ),
                    endAdornment: searchValue && (
                        <IconButton onClick={() => setSearchValue("")}>
                            <ClearOutlinedIcon fontSize="small" />
                        </IconButton>
                    ),
                }}
                sx={{
                    margin: "0 10px",
                    flexGrow: 1,
                }}
                variant="outlined"
            />
        </Box>
    );
};

export default CollectionFilterContainer;
