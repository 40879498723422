import { useMemo } from "react";

import { CategoryResponse, QueryDataResponse } from "src/types/services";
import { getData } from "src/util/service";

import { useQuery } from "@tanstack/react-query";

const useCategories = () => {
    const { data: dataCategories } = useQuery<QueryDataResponse<CategoryResponse, false>>({
        staleTime: Infinity,
        cacheTime: 0,
        queryKey: ["categories"],
        queryFn: () => getData({ path: "/categories" }),
    });

    return useMemo(() => dataCategories?.items || [], [dataCategories?.items]);
};

export default useCategories;
