import { UpdateProductForm } from "src/types/forms";
import { putData } from "src/util/service";

import { useMutation } from "@tanstack/react-query";
import useShowSnackbar from "src/hooks/useShowSnackbar";

const useUpdateProduct = ({
    productId,
    onSuccess,
}: {
    productId: string;
    onSuccess: (data: any) => void;
}) => {
    const showSnackbar = useShowSnackbar();

    return useMutation({
        mutationKey: ["update-product"],
        mutationFn: (data: UpdateProductForm) =>
            putData<UpdateProductForm>("/products/with-admin", data, productId),
        onSuccess(data) {
            showSnackbar("Cập nhật sản phẩm thành công!", "success");
            onSuccess(data.data);
        },
        onError() {
            showSnackbar("Cập nhật sản phẩm thất bại!", "error");
        },
    });
};

export default useUpdateProduct;
