import { Button } from "@mui/material";

import ButtonLink from "./ButtonLink";

type ActionButtonSectionProps = {
    actionOnClick: () => void;
    backTo: string;
};
const ActionButtonSection = ({ actionOnClick, backTo }: ActionButtonSectionProps) => {
    return (
        <>
            <ButtonLink to={backTo} color="secondary">
                Hủy
            </ButtonLink>
            <Button
                variant="contained"
                sx={{
                    marginLeft: "10px",
                }}
                onClick={actionOnClick}>
                Lưu
            </Button>
        </>
    );
};

export default ActionButtonSection;
