import { Control, Controller, FieldValues, Path } from "react-hook-form";

import { TextField, TextFieldProps } from "@mui/material";

import InputLabel from "../InputLabel";

const TextFieldHF = <T extends FieldValues>({
    control,
    name,
    required,
    labelOverride,
    type,
    ...restTextFieldProps
}: {
    control: Control<T>;
    name: Path<T>;
    labelOverride?: string;
} & TextFieldProps) => {
    return (
        <>
            {labelOverride ? (
                <InputLabel>
                    {labelOverride}
                    {required ? "*" : null}
                </InputLabel>
            ) : null}
            <Controller
                name={name}
                control={control}
                render={({ field: { onChange, ...restField }, formState: { errors } }) => (
                    <TextField
                        required={required ? true : undefined}
                        fullWidth
                        size="small"
                        autoComplete={name}
                        onChange={(e) => {
                            if (type === "number") {
                                onChange(parseInt(e.target.value || "0"));
                            } else {
                                onChange(e);
                            }
                        }}
                        error={!!errors[name]?.message}
                        helperText={errors[name]?.message as string}
                        type={type}
                        {...restField}
                        {...restTextFieldProps}
                    />
                )}
            />
        </>
    );
};

export default TextFieldHF;
