import { Link } from "react-router-dom";
import { ProductResponse } from "src/types/services";

import { Box, Checkbox, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBodySkeleton from "src/components/TableBodySkeleton";

interface ProductTableProps {
    products?: ProductResponse[];
    isLoading: boolean;
}

const ProductTable = ({ products, isLoading }: ProductTableProps) => {
    return products && products.length ? (
        <>
            <TableContainer>
                <Table sx={{ minWidth: 650 }}>
                    <TableHead>
                        <TableRow>
                            <TableCell width={"5%"} padding={"checkbox"}>
                                <Checkbox />
                            </TableCell>
                            <TableCell width={"50%"}>Tên sản phẩm</TableCell>
                            <TableCell width={"20%"}>Loại</TableCell>
                            <TableCell width={"30%"}>Nhà cung cấp</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(products || []).map(
                            ({
                                id,
                                images,
                                name,
                                category: { name: categoryName },
                                vendor: { name: vendorName },
                            }) => (
                                <TableRow key={id}>
                                    <TableCell component="th" scope="row" padding={"checkbox"}>
                                        <Checkbox />
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        <Box display={"flex"} alignItems={"center"}>
                                            <Box className="table-cell--image">
                                                {images ? (
                                                    <img
                                                        className="box-image"
                                                        src={images[0].url}
                                                        alt={images[0].alt}
                                                    />
                                                ) : (
                                                    <img
                                                        className="box-image"
                                                        src="/images/nothing-image.png"
                                                        alt=""
                                                    />
                                                )}
                                            </Box>
                                            <Typography variant="body2" flexGrow={1} ml={1}>
                                                <Link
                                                    to={`/products/${id}/edit`}
                                                    style={{
                                                        color: "#2962ff",
                                                        textDecoration: "none",
                                                    }}>
                                                    {name}
                                                </Link>
                                            </Typography>
                                        </Box>
                                    </TableCell>
                                    <TableCell>{categoryName}</TableCell>
                                    <TableCell>{vendorName}</TableCell>
                                </TableRow>
                            )
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    ) : (
        <>
            <TableContainer>
                <Table sx={{ minWidth: 650 }}>
                    <TableHead>
                        <TableRow>
                            <TableCell width={"5%"} padding={"checkbox"}>
                                <Checkbox />
                            </TableCell>
                            <TableCell width={"50%"}>Tên sản phẩm</TableCell>
                            <TableCell width={"20%"}>Loại</TableCell>
                            <TableCell width={"30%"}>Nhà cung cấp</TableCell>
                        </TableRow>
                    </TableHead>
                    {isLoading ? (
                        <TableBody>
                            <TableBodySkeleton numberColumn={4} />
                        </TableBody>
                    ) : null}
                </Table>
            </TableContainer>
            {!isLoading ? (
                <Box display={"flex"} textAlign={"center"} justifyContent={"center"}>
                    <Typography variant="caption" fontStyle={"italic"} py={2} alignSelf={"center"}>
                        Không có dữ liệu phù hợp
                    </Typography>
                </Box>
            ) : null}
        </>
    );
};

export default ProductTable;
