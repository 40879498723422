import { useContext, useMemo } from "react";

import { GlobalContext } from "src/shares/GlobalProvider";

const useShowSnackbar = () => {
    const { showSnackbar } = useContext(GlobalContext);

    return useMemo(() => showSnackbar, [showSnackbar]);
};

export default useShowSnackbar;
