import { Image } from "antd";
import { convertToDate, numberToCurrency, urlImageWithEmpty } from "src/util/common";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { Box, Grid, InputAdornment, Stack, TextField, Typography } from "@mui/material";
import ButtonLink from "src/components/ButtonLink";
import PageWrapper from "src/components/PageWrapper";
import { PaperCustom } from "src/components/PaperCustom";

import useOrders from "src/hooks/useOrders";

const OrdersPage = () => {
    const { orders } = useOrders();

    return (
        <PageWrapper
            title="Danh sách đơn hàng"
            actionArea={
                <ButtonLink to={"/orders/create"} icon={<AddCircleOutlineIcon />}>
                    Tạo đơn hàng
                </ButtonLink>
            }>
            <PaperCustom>
                <Box display={"flex"} width={"100%"} mb={1} pt={1}>
                    <TextField
                        size="small"
                        placeholder="Tìm kiếm"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchOutlinedIcon fontSize="small" />
                                </InputAdornment>
                            ),
                        }}
                        sx={{
                            flexGrow: 1,
                        }}
                        variant="outlined"
                    />
                </Box>
                <Stack rowGap={1}>
                    {orders.map(
                        ({
                            order_id,
                            created_at,
                            payment_method,
                            payment_status,
                            first_name,
                            last_name,
                            address,
                            phone_number,
                            note,
                            order_item,
                            shipping_price,
                            order_status,
                        }) => (
                            <Stack
                                key={order_id}
                                border={(theme) => `1px solid ${theme.palette.divider}`}
                                p={1}
                                borderRadius={"4px"}>
                                <Box display={"flex"} columnGap={3}>
                                    <Box>
                                        <Typography variant="caption">Ngày tạo</Typography>
                                        <Typography variant="body2" fontWeight={"bold"}>
                                            {convertToDate(created_at)}
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <Typography variant="caption">Thanh toán</Typography>
                                        <Typography variant="body2" fontWeight={"bold"}>
                                            {payment_method}
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <Typography variant="caption">
                                            Trạng thái thanh toán
                                        </Typography>
                                        <Typography variant="body2" fontWeight={"bold"}>
                                            {payment_status}
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <Typography variant="caption">Phí giao hàng</Typography>
                                        <Typography variant="body2" fontWeight={"bold"}>
                                            {numberToCurrency(shipping_price)}
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <Typography variant="caption">
                                            Trạng thái đơn hàng
                                        </Typography>
                                        <Typography variant="body2" fontWeight={"bold"}>
                                            {order_status}
                                        </Typography>
                                    </Box>
                                </Box>
                                <Grid container spacing={1} mt={2}>
                                    <Grid item xs={12} md={4}>
                                        <Typography variant="body2" fontWeight={"bold"} mb={1.5}>
                                            Thông tin giao hàng:
                                        </Typography>
                                        <Stack gap={1} px={1}>
                                            <Typography
                                                variant="body1"
                                                color={"cornflowerblue"}
                                                fontWeight={"bold"}>
                                                Tên: {`${last_name} ${first_name}`}
                                            </Typography>
                                            <Typography variant="body2">
                                                Địa chỉ: {address}
                                            </Typography>
                                            <Typography variant="body2">
                                                SĐT: {phone_number}
                                            </Typography>
                                            <Typography variant="body2">
                                                Note: {note ? note : "--"}
                                            </Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12} md={8}>
                                        <Stack rowGap={1} p={1}>
                                            {order_item.map((orderItem, index) => {
                                                return (
                                                    <Box
                                                        display={"flex"}
                                                        key={orderItem.product_id + index}
                                                        gap={1}>
                                                        <Image
                                                            width={60}
                                                            src={urlImageWithEmpty(
                                                                orderItem.image.url
                                                            )}
                                                        />
                                                        <Stack width={"100%"}>
                                                            <Box
                                                                display={"flex"}
                                                                justifyContent={"space-between"}
                                                                columnGap={2}
                                                                width={"100%"}>
                                                                <Typography
                                                                    variant="body1"
                                                                    fontWeight={"bold"}>
                                                                    {`${
                                                                        orderItem.product_name
                                                                    }  ${orderItem.attribute_value
                                                                        .filter(
                                                                            (av) =>
                                                                                av !==
                                                                                "Default Title"
                                                                        )
                                                                        .join(" - ")}`}
                                                                </Typography>
                                                                <Typography
                                                                    variant="body2"
                                                                    whiteSpace={"nowrap"}>
                                                                    x {orderItem.quantity}
                                                                </Typography>
                                                            </Box>
                                                            <Box
                                                                display={"flex"}
                                                                justifyContent={"flex-end"}>
                                                                <Typography
                                                                    variant="body2"
                                                                    whiteSpace={"nowrap"}>
                                                                    {numberToCurrency(
                                                                        orderItem.unique_price
                                                                    )}
                                                                </Typography>
                                                            </Box>
                                                        </Stack>
                                                    </Box>
                                                );
                                            })}

                                            <Box display={"flex"} justifyContent={"flex-end"}>
                                                <Typography
                                                    variant="body1"
                                                    fontWeight={"bold"}
                                                    whiteSpace={"nowrap"}>
                                                    Tổng:{" "}
                                                    {numberToCurrency(
                                                        order_item.reduce(
                                                            (pre, cur) => (pre += cur.price),
                                                            0
                                                        )
                                                    )}
                                                </Typography>
                                            </Box>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </Stack>
                        )
                    )}
                </Stack>
            </PaperCustom>
        </PageWrapper>
    );
};

export default OrdersPage;
