import { Dispatch, SetStateAction, useEffect, useState } from "react";

import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import { Grid, Stack, Typography } from "@mui/material";

const ImageInputSection = ({
    setImage,
    defaultSrc,
}: {
    setImage?: Dispatch<SetStateAction<any>>;
    defaultSrc?: string;
}) => {
    const [imageSrc, setImageSrc] = useState<string>(defaultSrc || "");

    useEffect(() => {
        setImageSrc(defaultSrc || "");
    }, [defaultSrc]);

    const handleChange = (e: any) => {
        setImageSrc(URL.createObjectURL(e.target.files[0]));
        setImage && setImage(e.target.files[0]);
    };

    return (
        <Grid
            item
            container
            width={"100%"}
            minHeight={"150px"}
            justifyContent={"center"}
            alignItems={"center"}
            position={"relative"}>
            <Grid item xs={12}>
                {imageSrc ? (
                    <img src={imageSrc} alt="collection" width={"100%"} height={"100%"} />
                ) : null}
                <Stack
                    component={"label"}
                    htmlFor="add-image-input"
                    style={{
                        cursor: "pointer",
                        alignItems: "center",
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                    }}>
                    {imageSrc ? (
                        ""
                    ) : (
                        <>
                            <CloudUploadOutlinedIcon fontSize={"large"} />
                            <Typography variant="body2">Tải Hình Ảnh</Typography>
                        </>
                    )}
                </Stack>
                <input
                    id="add-image-input"
                    type="file"
                    multiple
                    accept="image/jpeg,.jpeg,.jpg,image/png,.png,image/gif,.gif"
                    style={{ display: "none" }}
                    onChange={handleChange}
                />
            </Grid>
        </Grid>
    );
};

export default ImageInputSection;
