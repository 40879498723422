import { Skeleton, TableCell, TableRow } from "@mui/material";

const TableBodySkeleton = ({ numberColumn }: { numberColumn: number }) => {
    return (
        <>
            {[...Array(10)].map((_, index) => (
                <TableRow key={`row-skeleton-${index}`}>
                    {[...Array(numberColumn)].map((_, index) => (
                        <TableCell key={`cell-skeleton-${index}`}>
                            <Skeleton />
                        </TableCell>
                    ))}
                </TableRow>
            ))}
        </>
    );
};

export default TableBodySkeleton;
