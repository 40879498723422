import { SignUpForm } from "src/types/forms";
import { postData } from "src/util/service";

import { useMutation } from "@tanstack/react-query";
import useShowSnackbar from "src/hooks/useShowSnackbar";

const useMutationSignUp = ({ onSuccess }: { onSuccess: () => void }) => {
    const showSnackbar = useShowSnackbar();

    return useMutation({
        mutationKey: ["sign-up"],
        mutationFn: (data: SignUpForm) => postData<SignUpForm>("/admins/sign-up", data),
        onSuccess() {
            showSnackbar("Đăng ký tài khoản thành công!", "success");

            onSuccess();
        },
        onError() {
            showSnackbar("Đăng ký tài khoản thất bại!", "error");
        },
    });
};

export default useMutationSignUp;
