import { useState } from "react";

import { UploadFile } from "antd";
import axios from "axios";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { mutateProductValidateSchema } from "src/types/form-validations";
import { ProductForm, ProductVariantForm, VariantItemsForm } from "src/types/forms";

import { Checkbox, FormControlLabel, Grid, InputLabel } from "@mui/material";
import ActionButtonSection from "src/components/ActionButtonSection";
import CreateVariantSection from "src/components/CreateVariantSection";
import PageWrapper from "src/components/PageWrapper";
import { PaperContentSection } from "src/components/PaperContentSection";
import PictureWallInput from "src/components/PictureWallInput";
import AutocompleteHF from "src/components/rhf/AutocompleteHF";
import TextFieldHF from "src/components/rhf/TextFieldHF";

import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { joiResolver } from "@hookform/resolvers/joi";
import useCategories from "src/hooks/useCategories";
import useCollections from "src/hooks/useCollections";
import useCreateProduct from "src/hooks/useCreateProduct";
import useShowOverlayLoading from "src/hooks/useShowOverlayLoading";
import useUploadImage from "src/hooks/useUploadImage";
import useVendors from "src/hooks/useVendors";

require("@ckeditor/ckeditor5-build-classic/build/translations/vi");

const CreateProductPage = () => {
    const navigate = useNavigate();
    const showLoading = useShowOverlayLoading();

    const categoriesData = useCategories();
    const vendorsData = useVendors();
    const { collections } = useCollections({ initialOffset: 500 });

    const { mutate: onCreate } = useCreateProduct({
        onSuccess: () => {
            showLoading(false);

            navigate("/products");
        },
    });

    const { mutateAsync: onAsyncUploadImage } = useUploadImage();

    const [productImageList, setProductImageList] = useState<UploadFile[]>([]);

    const { handleSubmit, control, setValue } = useForm<ProductForm>({
        defaultValues: {
            name: "",
            description: "",
            is_visible: true,
            collection_ids: [],
            quote: "",
            type: 0,
            category_id: "",
            vendor_id: "",
        },
        resolver: joiResolver(mutateProductValidateSchema),
    });

    const { control: controlVariantForm } = useForm<VariantItemsForm>({
        defaultValues: {
            variantItems: [],
        },
    });

    const {
        fields: fieldsVariant,
        update: updateVariant,
        replace: replaceVariant,
    } = useFieldArray<VariantItemsForm, "variantItems">({
        control: controlVariantForm,
        name: "variantItems",
    });

    const onSubmit = async (data: ProductForm) => {
        showLoading(true);
        const selectedVariant = fieldsVariant.filter((e) => e.isChecked);
        const variants: ProductVariantForm[] = selectedVariant.map(
            ({ attribute_values, barcode, price, sku }) => ({
                attribute_values,
                barcode: barcode ?? "",
                sku: sku ?? "",
                cost_price: 0,
                retail_price: price,
                image: null,
                stock_quantity: 0,
            })
        );
        const dataSave: ProductForm = {
            ...data,
            variants,
            images: [],
        };

        if (productImageList.length) {
            const productImagesUploader: Promise<{ url: string }>[] = productImageList.map((file) =>
                onAsyncUploadImage(file.originFileObj).then((res) => res.data)
            );

            await axios.all(productImagesUploader).then((dataResponses) => {
                const imagesPayload = dataResponses.map((data) => ({
                    url: data.url,
                }));

                onCreate({ ...dataSave, images: [...imagesPayload] });
            });
        } else {
            onCreate(dataSave);
        }
    };

    return (
        <PageWrapper
            title="Tạo sản phẩm"
            actionArea={
                <ActionButtonSection actionOnClick={handleSubmit(onSubmit)} backTo="/products" />
            }>
            <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={8}>
                    <PaperContentSection title="Thông tin chung" topSection>
                        <Grid container spacing={2} p={2}>
                            <Grid item xs={12}>
                                <TextFieldHF
                                    control={control}
                                    name="name"
                                    labelOverride="Tên sản phẩm"
                                    placeholder="Tên sản phẩm"
                                    required
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <AutocompleteHF
                                    formControlProps={{
                                        control,
                                        name: "vendor_id",
                                    }}
                                    required
                                    labelOverride="Nhà cung cấp"
                                    placeholder="Chọn nhà cung cấp"
                                    options={vendorsData.map((vendor) => ({
                                        label: vendor.name,
                                        value: vendor.id,
                                    }))}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <AutocompleteHF
                                    formControlProps={{
                                        control,
                                        name: "category_id",
                                    }}
                                    required
                                    labelOverride="Loại sản phẩm"
                                    placeholder="Chọn loại sản phẩm"
                                    options={categoriesData.map((category) => ({
                                        label: category.name,
                                        value: category.id,
                                    }))}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <InputLabel>Mô tả sản phẩm</InputLabel>
                                <CKEditor
                                    config={{
                                        language: "vi",
                                    }}
                                    data={""}
                                    editor={ClassicEditor}
                                    onChange={(_, editor) => {
                                        const data = editor.getData();
                                        setValue("description", data);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextFieldHF
                                    control={control}
                                    name="quote"
                                    placeholder="Trích dẫn sản phẩm"
                                    labelOverride="Trích dẫn"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    name="is_visible"
                                    control={control}
                                    render={({ field }) => (
                                        <FormControlLabel
                                            control={<Checkbox defaultChecked />}
                                            label="Hiển thị sản phẩm"
                                            {...field}
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </PaperContentSection>
                    <PaperContentSection
                        title="Hình Ảnh Sản Phẩm"
                        titleTooltip="Ảnh định dạng jpg, jpeg, png, gif tỉ lệ 1:1 (ảnh vuông) và độ phân giải 2048px x 2048px để chất lượng hình ảnh tốt nhất">
                        <Grid container spacing={2} p={2}>
                            <Grid item xs={12}>
                                <PictureWallInput
                                    fileList={productImageList}
                                    updateFileList={(images) => setProductImageList(images)}
                                    multiple
                                />
                            </Grid>
                        </Grid>
                    </PaperContentSection>
                    <CreateVariantSection
                        fields={fieldsVariant}
                        replace={replaceVariant}
                        update={updateVariant}
                    />
                </Grid>
                <Grid item xs={12} md={12} lg={4}>
                    <PaperContentSection title="Nhóm Sản Phẩm" topSection>
                        <Grid container spacing={2} p={2}>
                            <Grid item xs={12}>
                                <AutocompleteHF<ProductForm, true>
                                    formControlProps={{
                                        control,
                                        name: "collection_ids",
                                    }}
                                    multiple
                                    placeholder="Chọn nhóm sản phẩm"
                                    options={collections.map((collection) => ({
                                        label: collection.title,
                                        value: collection.id,
                                    }))}
                                />
                            </Grid>
                        </Grid>
                    </PaperContentSection>
                </Grid>
            </Grid>
        </PageWrapper>
    );
};

export default CreateProductPage;
