import axios, { AxiosStatic } from "axios";
import { ADMIN_REQUEST_TOKEN_KEY } from "src/constants/user";
import { PathDelete, PathGet, PathPost, PathPut } from "src/types/services";

import { getCookie } from "./cookie";

export const getData = ({
    path,
    config = undefined,
    slug = "",
    requiredAuth = false,
}: {
    path: PathGet;
    config?: Parameters<AxiosStatic["get"]>[1];
    slug?: string;
    requiredAuth?: boolean;
}) => {
    const token = getCookie(ADMIN_REQUEST_TOKEN_KEY);
    const pathname = slug ? `${path}/${slug}` : `${path}`;

    return axios
        .get(`${process.env.REACT_APP_API_END_POINT}${pathname}`, {
            headers: {
                ...(requiredAuth ? { Authorization: `Bearer ${token}` } : {}),
                ...config?.headers,
            },
            ...config,
        })
        .then((res) => res.data);
};

export const postData = <D>(
    path: PathPost,
    data: D,
    config: Parameters<AxiosStatic["post"]>[2] = undefined
) => {
    const token = getCookie(ADMIN_REQUEST_TOKEN_KEY);

    return axios.post(`${process.env.REACT_APP_API_END_POINT}${path}`, data, {
        headers: {
            Authorization: `Bearer ${token}`,
            ...config?.headers,
        },
        ...config,
    });
};

export const putData = <D>(
    path: PathPut,
    data: D,
    id: string = "",
    config: Parameters<AxiosStatic["put"]>[2] = undefined
) => {
    const token = getCookie(ADMIN_REQUEST_TOKEN_KEY);

    const pathname = id ? `${path}/${id}` : `${path}`;
    return axios.put(`${process.env.REACT_APP_API_END_POINT}${pathname}`, data, {
        headers: {
            Authorization: `Bearer ${token}`,
            ...config?.headers,
        },
        ...config,
    });
};

export const deleteData = (
    path: PathDelete,
    id: string,
    config: Parameters<AxiosStatic["delete"]>[1] = undefined
) => {
    const token = getCookie(ADMIN_REQUEST_TOKEN_KEY);

    return axios.delete(`${process.env.REACT_APP_API_END_POINT}${path}/${id}`, {
        headers: {
            Authorization: `Bearer ${token}`,
            ...config?.headers,
        },
        ...config,
    });
};
