export function setCookie({
    key,
    value,
    dayOffset,
}: {
    key: string;
    value: string;
    dayOffset: number;
}) {
    const d = new Date();
    d.setTime(d.getTime() + dayOffset * 24 * 60 * 60 * 1000);
    let expires = "expires=" + d.toUTCString();
    document.cookie = key + "=" + value + ";" + expires + ";path=/";
}

export function getCookie(key: string) {
    let name = key + "=";
    let ca = document.cookie.split(";");
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === " ") {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return JSON.parse(c.substring(name.length, c.length));
        }
    }
    return "";
}

export const removeCookie = (key: string) => {
    setCookie({
        key,
        value: "",
        dayOffset: 0,
    });
};
