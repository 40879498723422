import { OrderResponse, QueryDataResponse } from "src/types/services";
import { getData } from "src/util/service";

import { useQuery } from "@tanstack/react-query";
import useGetQueryUtil from "src/hooks/useGetQueryUtil";

const useOrders = () => {
    const { enabled, nameDebounce, onReset, onSearch } = useGetQueryUtil();

    const { data: dataOrders, ...rest } = useQuery<QueryDataResponse<OrderResponse>>({
        enabled,
        staleTime: Infinity,
        cacheTime: 0,
        queryKey: ["orders", { name: nameDebounce }],
        queryFn: () =>
            getData({
                path: "/orders/with-admin",
                config: {
                    params: {
                        name: nameDebounce,
                        limit: -1,
                        offset: 0,
                    },
                },
                requiredAuth: true,
            }),
    });

    return {
        orders: dataOrders?.items || [],
        onSearch,
        onReset,
        ...rest,
    };
};

export default useOrders;
