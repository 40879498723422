import { Link } from "react-router-dom";
import { CollectionResponse } from "src/types/services";

import { Box, Checkbox, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBodySkeleton from "src/components/TableBodySkeleton";

interface CollectionTableProps {
    collections?: CollectionResponse[];
    isLoading?: boolean;
}

const CollectionTable = ({ collections, isLoading }: CollectionTableProps) => {
    return collections && collections.length ? (
        <TableContainer>
            <Table sx={{ minWidth: 650 }}>
                <TableHead>
                    <TableRow>
                        <TableCell width={"5%"} padding={"checkbox"}>
                            <Checkbox />
                        </TableCell>
                        <TableCell width={"65%"}>Tên nhóm</TableCell>
                        <TableCell width={"30%"}>Điều kiện nhóm</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {collections.map(({ id, image, title }) => (
                        <TableRow key={id}>
                            <TableCell component="th" scope="row" padding={"checkbox"}>
                                <Checkbox />
                            </TableCell>
                            <TableCell>
                                <Box display={"flex"} alignItems={"center"}>
                                    <Box className="table-cell--image">
                                        {image ? (
                                            <img
                                                className="box-image"
                                                src={image.url}
                                                alt={image.url}
                                            />
                                        ) : (
                                            <img
                                                className="box-image"
                                                src="/images/nothing-image.png"
                                                alt=""
                                            />
                                        )}
                                    </Box>
                                    <Typography variant="body2" flexGrow={1} ml={1}>
                                        <Link
                                            to={`/collections/${id}/edit`}
                                            style={{
                                                color: "#2962ff",
                                                textDecoration: "none",
                                            }}>
                                            {title ? title : "--"}
                                        </Link>
                                    </Typography>
                                </Box>
                            </TableCell>
                            <TableCell>--</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    ) : (
        <>
            <TableContainer>
                <Table sx={{ minWidth: 650 }}>
                    <TableHead>
                        <TableRow>
                            <TableCell width={"5%"} padding={"checkbox"}>
                                <Checkbox />
                            </TableCell>
                            <TableCell width={"65%"}>Tên nhóm</TableCell>
                            <TableCell width={"30%"}>Điều kiện nhóm</TableCell>
                        </TableRow>
                    </TableHead>
                    {isLoading ? (
                        <TableBody>
                            <TableBodySkeleton numberColumn={3} />
                        </TableBody>
                    ) : null}
                </Table>
            </TableContainer>
            {!isLoading ? (
                <Box display={"flex"} textAlign={"center"} justifyContent={"center"}>
                    <Typography variant="caption" fontStyle={"italic"} py={2} alignSelf={"center"}>
                        Không có dữ liệu phù hợp
                    </Typography>
                </Box>
            ) : null}
        </>
    );
};

export default CollectionTable;
