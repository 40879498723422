import { PropsWithChildren } from "react";

import { Typography } from "@mui/material";

const InputLabel = ({ children }: PropsWithChildren) => {
    return (
        <Typography variant="body2" pb={0.5} fontWeight={700}>
            {children}
        </Typography>
    );
};

export default InputLabel;
