import { useState } from "react";

import { useNavigate } from "react-router-dom";
import { ADMIN_PROFILE_STORAGE_KEY, ADMIN_REQUEST_TOKEN_KEY } from "src/constants/user";
import { removeCookie } from "src/util/cookie";
import { makeStyles } from "tss-react/mui";

import { Box, Menu, MenuItem, Typography } from "@mui/material";

import useAdminProfile from "src/hooks/useAdminProfile";

const useStyles = makeStyles()(() => ({
    container: {
        display: "flex",
        alignItems: "center",
    },
    username: {
        fontWeight: 700,
        color: "var(--secondary-color)",
    },
    avatarImage: {
        width: "40px",
        height: "40px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginLeft: "10px",
        backgroundColor: "var(--primary-color)",
        color: "white",
        borderRadius: "50%",
        cursor: "pointer",
    },
}));

const ProfileAvatar = () => {
    const { classes } = useStyles();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const navigate = useNavigate();
    const {
        adminProfile: { name },
    } = useAdminProfile();

    const open = Boolean(anchorEl);
    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleLogout = () => {
        localStorage.removeItem(ADMIN_PROFILE_STORAGE_KEY);
        removeCookie(ADMIN_REQUEST_TOKEN_KEY);

        handleClose();

        navigate("/login");
    };

    return (
        <Box className={classes.container}>
            <Typography variant="body2" className={classes.username}>
                {name}
            </Typography>
            <Box id="basic-button" className={classes.avatarImage} onClick={handleClick}>
                {name[0]}
            </Box>

            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    "aria-labelledby": "basic-button",
                }}>
                <MenuItem onClick={handleClose}>Tài khoản</MenuItem>
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
        </Box>
    );
};

export default ProfileAvatar;
