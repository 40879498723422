import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@mui/material";

interface ConfirmedDialogProps {
    show: boolean;
    onClose: (isAccept: boolean) => void;
    message?: string;
    title?: string;
}
const ConfirmedDialog = ({
    show,
    onClose,
    message = " Bạn có chắc chắn với thao tác vừa rồi?",
    title = "Xác nhận thao tác",
}: ConfirmedDialogProps) => {
    return (
        <Dialog
            open={show}
            sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">{message}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onClose(false)}>Hủy</Button>
                <Button variant="contained" color="primary" onClick={() => onClose(true)}>
                    Xác nhận
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmedDialog;
