import { PropsWithChildren } from "react";

import { Box, Stack, Typography } from "@mui/material";

interface PageWrapperProps {
    title: string;
    actionArea?: JSX.Element;
}

const PageWrapper = ({ children, title, actionArea }: PropsWithChildren<PageWrapperProps>) => {
    return (
        <Stack width={"100%"}>
            <Box display={"flex"} justifyContent={"space-between"} py={2}>
                <Typography variant="h5">{title}</Typography>
                {actionArea ? <Box>{actionArea}</Box> : null}
            </Box>
            {children}
        </Stack>
    );
};

export default PageWrapper;
