import { ADMIN_PROFILE_STORAGE_KEY, ADMIN_REQUEST_TOKEN_KEY } from "src/constants/user";
import { AdminProfile } from "src/types/user";
import { getCookie } from "src/util/cookie";

const useAdminProfile = () => {
    const adminProfile = JSON.parse(
        localStorage.getItem(ADMIN_PROFILE_STORAGE_KEY) || "{}"
    ) as AdminProfile;

    const isExpiredLogin = getCookie(ADMIN_REQUEST_TOKEN_KEY) === "";

    return {
        adminProfile,
        isLogin: !isExpiredLogin && Object.keys(adminProfile).length >= 0,
    };
};

export default useAdminProfile;
