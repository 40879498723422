import { LoginForm } from "src/types/forms";
import { postData } from "src/util/service";

import { useMutation } from "@tanstack/react-query";
import useShowSnackbar from "src/hooks/useShowSnackbar";

const useMutationLogin = ({ onSuccess }: { onSuccess: (data: any) => void }) => {
    const showSnackbar = useShowSnackbar();

    return useMutation({
        mutationKey: ["login"],
        mutationFn: (data: LoginForm) => postData<LoginForm>("/admins/sign-in", data),
        onSuccess(data) {
            showSnackbar("Đăng nhập thành công!", "success");
            onSuccess(data.data);
        },
        onError() {
            showSnackbar("Tài khoản hoặc mật khẩu chưa chính xác!", "error");
        },
    });
};

export default useMutationLogin;
