import { useMemo } from "react";

import { AttributeResponse, QueryDataResponse } from "src/types/services";
import { getData } from "src/util/service";

import { useQuery } from "@tanstack/react-query";

const useAttributes = (): AttributeResponse[] => {
    const { data: dataAttributes } = useQuery<QueryDataResponse<AttributeResponse, false>>({
        staleTime: Infinity,
        cacheTime: 0,
        queryKey: ["attributes"],
        queryFn: () =>
            getData({
                path: "/attributes",
                config: {
                    params: {
                        limit: -1,
                        offset: 0,
                    },
                },
            }),
    });

    return useMemo(() => dataAttributes?.items ?? [], [dataAttributes?.items]);
};

export default useAttributes;
