import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { Box } from "@mui/material";
import ButtonLink from "src/components/ButtonLink";
import CollectionFilterContainer from "src/components/CollectionFilterContainer";
import PageWrapper from "src/components/PageWrapper";
import { PaperCustom } from "src/components/PaperCustom";
import CollectionTable from "src/components/tables/CollectionTable";
import TablePaginationWrapper from "src/components/tables/TablePaginationWrapper";

import useCollections from "src/hooks/useCollections";

const CollectionsPage = () => {
    const { collections, pagination, onSearch, isLoading } = useCollections();

    return (
        <PageWrapper
            title="Danh sách nhóm sản phẩm"
            actionArea={
                <ButtonLink to={"/collections/create"} icon={<AddCircleOutlineIcon />}>
                    Tạo nhóm sản phẩm
                </ButtonLink>
            }>
            <PaperCustom>
                <CollectionFilterContainer onSearch={onSearch} />
                <CollectionTable collections={collections} isLoading={isLoading} />

                <Box pt={1}>
                    <TablePaginationWrapper pagination={pagination} />
                </Box>
            </PaperCustom>
        </PageWrapper>
    );
};

export default CollectionsPage;
