import { OrderForm } from "src/types/forms";
import { postData } from "src/util/service";

import useShowOverlayLoading from "./useShowOverlayLoading";

import { useMutation } from "@tanstack/react-query";
import useShowSnackbar from "src/hooks/useShowSnackbar";

const useCreateOrder = ({ onSuccess }: { onSuccess: (data: any) => void }) => {
    const showSnackbar = useShowSnackbar();
    const showLoading = useShowOverlayLoading();

    return useMutation({
        mutationKey: ["create-order"],
        mutationFn: (data: OrderForm) => postData<OrderForm>("/orders", data),
        onSuccess(data) {
            showSnackbar("Tạo đơn hàng mới thành công!", "success");
            onSuccess(data.data);
        },
        onError() {
            showLoading(false);
            showSnackbar("Tạo đơn hàng mới thất bại!", "error");
        },
    });
};

export default useCreateOrder;
