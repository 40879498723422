import { useState } from "react";

import { Image, UploadFile } from "antd";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import { UpdateProductVariantForm } from "src/types/forms";

import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import ActionButtonSection from "src/components/ActionButtonSection";
import ConfirmedDialog from "src/components/dialogs/ConfirmedDialog";
import PageWrapper from "src/components/PageWrapper";
import { PaperContentSection } from "src/components/PaperContentSection";
import { PaperCustom } from "src/components/PaperCustom";
import PictureWallInput from "src/components/PictureWallInput";
import TextFieldHF from "src/components/rhf/TextFieldHF";

import useDeleteProductVariant from "src/hooks/useDeleteProductVariant";
import useProductDetail from "src/hooks/useProductDetail";
import useShowOverlayLoading from "src/hooks/useShowOverlayLoading";
import useUpdateProductVariant from "src/hooks/useUpdateProductVariant";
import useUploadImage from "src/hooks/useUploadImage";

const EditProductVariantPage = () => {
    const { id: productId, variantId } = useParams();
    const navigate = useNavigate();
    const showLoading = useShowOverlayLoading();

    const { mutate: onUpdate } = useUpdateProductVariant({
        productId: productId,
        variantId: variantId,
        onSuccess: () => {
            showLoading(false);
            navigate(`/products/${productId}/edit`);
        },
    });
    const { mutateAsync: onAsyncUploadImage } = useUploadImage();
    const { mutate: onDelete } = useDeleteProductVariant({
        productId: productId,
        onSuccess: () => {
            showLoading(false);
            navigate(`/products/${productId}/edit`);
        },
    });

    const { productDetail } = useProductDetail({
        id: productId,
        variantId: variantId,
        onSuccess: ({ variants }) => {
            const selectedVariantForUpdateForm = variants.find(
                (productVariant) => productVariant.id === variantId
            );

            if (selectedVariantForUpdateForm) {
                const {
                    attributes,
                    barcode,
                    cost_price,
                    image_id,
                    retail_price,
                    sku,
                    stock_quantity,
                } = selectedVariantForUpdateForm;

                reset({
                    attribute_values: attributes.map(({ id, value }) => ({
                        attribute_id: id,
                        value,
                    })),
                    barcode,
                    sku,
                    cost_price,
                    retail_price,
                    image: image_id ? { url: image_id } : null,
                    stock_quantity,
                });

                if (image_id) {
                    setProductImageList([
                        {
                            uid: image_id,
                            name: "variant-image",
                            status: "done",
                            url: image_id,
                        },
                    ]);
                }
            }
        },
    });

    const { control, reset, handleSubmit } = useForm<UpdateProductVariantForm>({
        defaultValues: {
            attribute_values: [],
            barcode: "",
            sku: "",
            cost_price: 0,
            retail_price: 0,
            stock_quantity: 0,
            is_update_images: false,
        },
    });

    const [isShowConfirmedDialog, setIsShowConfirmedDialog] = useState<boolean>(false);
    const [productImageList, setProductImageList] = useState<UploadFile[]>([]);

    const selectedVariant = productDetail?.variants.find(
        (productVariant) => productVariant.id === variantId
    );

    const onSave = async (data: UpdateProductVariantForm) => {
        showLoading(true);
        if (productImageList.length && productImageList[0].status !== "done") {
            onAsyncUploadImage(productImageList[0].originFileObj).then((res) => {
                const newImage = { url: res.data.url };

                onUpdate({ ...data, image: newImage, is_update_images: true });
            });
        } else {
            onUpdate({ ...data });
        }
    };

    const handleVariantDeleteConfirmedDialog = (isAccept: boolean) => {
        setIsShowConfirmedDialog(false);

        if (isAccept) {
            showLoading(true);
            onDelete(variantId || "");
        }
    };

    return (
        <PageWrapper
            title={selectedVariant?.attributes.map((svAttr) => svAttr.value).join(" / ") || ""}
            actionArea={
                <ActionButtonSection
                    actionOnClick={handleSubmit(onSave)}
                    backTo={`/products/${productId}/edit`}
                />
            }>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={4}>
                    <PaperCustom flexDirection={"row"} alignItems={"center"}>
                        <Image
                            width={100}
                            wrapperStyle={{ marginRight: 10 }}
                            src={
                                productDetail?.images !== null && productDetail?.images[0]
                                    ? productDetail?.images[0]?.url
                                    : "/images/nothing-image.png"
                            }
                        />
                        <Stack justifyItems={"center"}>
                            <Typography variant="body2" fontWeight={"bold"}>
                                {productDetail?.name}
                            </Typography>
                            <Typography variant="caption" fontStyle={"italic"}>
                                {productDetail?.variants.length} biến thể
                            </Typography>
                            <Link
                                to={`/products/${productId}/edit`}
                                style={{
                                    color: "#2962ff",
                                    textDecoration: "none",
                                }}>
                                <Typography variant="caption">Quay về chi tiết sản phẩm</Typography>
                            </Link>
                        </Stack>
                    </PaperCustom>
                    {productDetail && productDetail?.variants.length > 1 ? (
                        <Box
                            bgcolor={"white"}
                            boxShadow={"0 2px 4px rgb(0 0 0 / 10%)"}
                            borderRadius={"4px"}
                            mt={3}>
                            <Box
                                p={1}
                                borderBottom={(theme) => `1px solid ${theme.palette.divider}`}>
                                <Typography variant="body2" fontWeight={"bold"}>
                                    Biến thể khác
                                </Typography>
                            </Box>

                            <Stack maxHeight={350} overflow={"auto"}>
                                {productDetail?.variants
                                    .filter(
                                        (otherProductVariant) =>
                                            otherProductVariant.id !== variantId
                                    )
                                    .map((otherProductVariant) => {
                                        return (
                                            <Box
                                                key={otherProductVariant.id}
                                                display={"flex"}
                                                p={1}
                                                borderBottom={(theme) =>
                                                    `1px solid ${theme.palette.divider}`
                                                }>
                                                <Image
                                                    width={70}
                                                    wrapperStyle={{ marginRight: 10 }}
                                                    src={
                                                        otherProductVariant.image_id !== null
                                                            ? otherProductVariant.image_id
                                                            : "/images/nothing-image.png"
                                                    }
                                                />
                                                <Stack justifyItems={"center"}>
                                                    <Typography variant="body2" fontWeight={"bold"}>
                                                        {otherProductVariant.attributes
                                                            .map((opa) => opa.value)
                                                            .join(" / ")}
                                                    </Typography>
                                                    <Link
                                                        to={`/products/${productId}/variants/${otherProductVariant.id}/edit`}
                                                        style={{
                                                            color: "#2962ff",
                                                            textDecoration: "none",
                                                        }}>
                                                        <Typography variant="caption">
                                                            Tới Chi tiết biến thể
                                                        </Typography>
                                                    </Link>
                                                </Stack>
                                            </Box>
                                        );
                                    })}
                            </Stack>
                        </Box>
                    ) : null}
                </Grid>
                <Grid item xs={12} md={6} lg={8}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={12} lg={6}>
                            <PaperContentSection
                                title="Thông tin thuộc tính biến thể"
                                sx={{ height: "100%" }}
                                topSection>
                                <Grid container spacing={2} p={2}>
                                    {selectedVariant?.attributes.map((variantAttribute, index) => {
                                        return (
                                            <Grid item xs={12} key={variantAttribute.id}>
                                                <TextFieldHF
                                                    control={control}
                                                    fullWidth
                                                    name={`attribute_values.${index}.value`}
                                                    labelOverride={variantAttribute.name}
                                                    placeholder={variantAttribute.name}
                                                />
                                            </Grid>
                                        );
                                    })}
                                </Grid>
                            </PaperContentSection>
                        </Grid>
                        <Grid item xs={12} md={12} lg={6}>
                            <PaperContentSection
                                title="Hình ảnh biến thể"
                                sx={{ height: "100%" }}
                                topSection>
                                <Grid container spacing={2} p={2}>
                                    <Grid item xs={12}>
                                        <PictureWallInput
                                            fileList={productImageList}
                                            updateFileList={(files) => setProductImageList(files)}
                                            limitFile={1}
                                        />
                                    </Grid>
                                </Grid>
                            </PaperContentSection>
                        </Grid>
                    </Grid>

                    <PaperContentSection title="Chi tiết biến thể">
                        <Grid container spacing={2} p={2}>
                            <Grid item xs={6}>
                                <TextFieldHF
                                    control={control}
                                    fullWidth
                                    name={"retail_price"}
                                    labelOverride={"Giá bán"}
                                    type="number"
                                    inputProps={{ min: 0 }}
                                    placeholder={"Giá bán"}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextFieldHF
                                    control={control}
                                    fullWidth
                                    name={"cost_price"}
                                    labelOverride={"Giá trần"}
                                    type="number"
                                    inputProps={{ min: 0 }}
                                    placeholder={"Giá trần"}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextFieldHF
                                    control={control}
                                    fullWidth
                                    name={"barcode"}
                                    labelOverride={"Barcode"}
                                    placeholder={"Barcode"}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextFieldHF
                                    control={control}
                                    fullWidth
                                    name={"sku"}
                                    labelOverride={"SKU"}
                                    placeholder={"SKU"}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextFieldHF<UpdateProductVariantForm>
                                    control={control}
                                    fullWidth
                                    name={"stock_quantity"}
                                    type="number"
                                    labelOverride={"Số lượng"}
                                    placeholder={"Số lượng"}
                                    inputProps={{ min: 0 }}
                                />
                            </Grid>
                        </Grid>
                    </PaperContentSection>
                    <Box py={2} display={"flex"} justifyContent={"end"}>
                        <Button
                            variant="contained"
                            color="error"
                            onClick={() => setIsShowConfirmedDialog(true)}
                            disabled={!productDetail || productDetail?.variants.length <= 1}>
                            Xóa
                        </Button>
                    </Box>
                </Grid>
            </Grid>
            <ConfirmedDialog
                show={isShowConfirmedDialog}
                onClose={handleVariantDeleteConfirmedDialog}
            />
        </PageWrapper>
    );
};

export default EditProductVariantPage;
