import { PropsWithChildren } from "react";

import { Link, LinkProps } from "react-router-dom";

import { Button, ButtonProps } from "@mui/material";

interface ButtonLinkProps {
    to: LinkProps["to"];
    icon?: ButtonProps["startIcon"];
    color?: ButtonProps["color"];
    sx?: ButtonProps["sx"];
}

const ButtonLink = ({
    to,
    icon,
    children,
    color = "primary",
    sx,
}: PropsWithChildren<ButtonLinkProps>) => {
    return (
        <Link
            to={to}
            style={{
                textDecoration: "none",
            }}>
            <Button startIcon={icon} variant="contained" color={color} sx={sx}>
                {children}
            </Button>
        </Link>
    );
};

export default ButtonLink;
