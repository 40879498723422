import { ProductResponse, QueryDataResponse } from "src/types/services";
import { getData } from "src/util/service";

import useGetQueryPagination from "./useGetQueryPagination";
import useGetQueryUtil from "./useGetQueryUtil";

import { useQuery } from "@tanstack/react-query";

export interface ProductsServiceResponse extends QueryDataResponse<ProductResponse> {}

const useProducts = ({
    idCollection,
    onSuccess,
}: {
    idCollection?: string;
    onSuccess?: ((data: ProductsServiceResponse) => void) | undefined;
}) => {
    const {
        enabled,
        nameDebounce,
        page,
        pageCount,
        changePaging,
        changePageOption,
        onReset,
        onSearch,
    } = useGetQueryUtil();

    const { data: dataProducts, ...rest } = useQuery<ProductsServiceResponse>({
        enabled,
        staleTime: Infinity,
        cacheTime: 0,
        queryKey: [
            "products",
            {
                offset: page * pageCount,
                pageCount,
                name: nameDebounce,
                collection_ids: idCollection,
            },
        ],
        queryFn: () =>
            getData({
                path: "/products",
                config: {
                    params: {
                        limit: pageCount,
                        offset: page * pageCount,
                        collection_ids: idCollection ? `["${idCollection}"]` : [],
                        name: nameDebounce,
                    },
                },
            }),
        onSuccess,
    });

    const pagination = useGetQueryPagination({
        total: dataProducts?.paging.total,
        page,
        pageCount,
        changePageOption,
        changePaging,
    });

    return {
        products: dataProducts?.items,
        onSearch,
        pagination,
        onReset,
        ...rest,
    };
};

export default useProducts;
