import { useCallback, useContext, useMemo } from "react";

import { GlobalContext } from "src/shares/GlobalProvider";

const useShowOverlayLoading = () => {
    const { showLoading, hideLoading } = useContext(GlobalContext);

    const showOverlayLoading = useCallback(
        (show: boolean) => {
            if (show) {
                showLoading();
            } else {
                hideLoading();
            }
        },
        [showLoading, hideLoading]
    );

    return useMemo(() => showOverlayLoading, [showOverlayLoading]);
};

export default useShowOverlayLoading;
