import { PAGE_COUNT } from "src/constants/pagination";
import { Pagination } from "src/types/paging";

import { TablePagination } from "@mui/material";

const TablePaginationWrapper = ({ pagination }: { pagination: Pagination }) => {
    return <TablePagination rowsPerPageOptions={PAGE_COUNT} component="div" {...pagination} />;
};

export default TablePaginationWrapper;
