import { CollectionResponse, QueryDataResponse } from "src/types/services";
import { getData } from "src/util/service";

import { useQuery } from "@tanstack/react-query";
import useGetQueryPagination from "src/hooks/useGetQueryPagination";
import useGetQueryUtil from "src/hooks/useGetQueryUtil";

const useCollections = ({ initialOffset }: { initialOffset?: number } = {}) => {
    const {
        enabled,
        nameDebounce,
        page,
        pageCount,
        changePaging,
        changePageOption,
        onReset,
        onSearch,
    } = useGetQueryUtil({ initialOffset });

    const { data: dataCollections, ...rest } = useQuery<QueryDataResponse<CollectionResponse>>({
        enabled,
        staleTime: Infinity,
        cacheTime: 0,
        queryKey: ["collections", { offset: page * pageCount, pageCount, name: nameDebounce }],
        queryFn: () =>
            getData({
                path: "/collections",
                config: {
                    params: {
                        limit: pageCount,
                        offset: page * pageCount,
                        name: nameDebounce,
                    },
                },
            }),
    });

    const pagination = useGetQueryPagination({
        total: dataCollections?.paging.total,
        page,
        pageCount,
        changePageOption,
        changePaging,
    });

    return {
        collections: dataCollections?.items || [],
        pagination,
        onSearch,
        onReset,
        ...rest,
    };
};

export default useCollections;
