import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { ADMIN_PROFILE_STORAGE_KEY, ADMIN_REQUEST_TOKEN_KEY } from "src/constants/user";
import { loginValidateSchema } from "src/types/form-validations";
import { LoginForm } from "src/types/forms";
import { setCookie } from "src/util/cookie";

import AuthSection from "src/components/AuthSection";
import TextFieldHF from "src/components/rhf/TextFieldHF";

import { joiResolver } from "@hookform/resolvers/joi";
import useMutationLogin from "src/hooks/useMutationLogin";

const LoginPage = () => {
    const { handleSubmit, control } = useForm<LoginForm>({
        defaultValues: {
            email: "",
            password: "",
        },
        resolver: joiResolver(loginValidateSchema),
    });
    const navigate = useNavigate();
    const { mutate: onLogin } = useMutationLogin({
        onSuccess: (data: any) => {
            setCookie({
                key: ADMIN_REQUEST_TOKEN_KEY,
                value: JSON.stringify(data.access_token),
                dayOffset: 3,
            });
            localStorage.setItem(ADMIN_PROFILE_STORAGE_KEY, JSON.stringify(data.profile));

            navigate("/");
        },
    });

    const onSubmit = (data: LoginForm) => {
        onLogin(data);
    };

    return (
        <AuthSection
            title="Đăng Nhập"
            actionButton={{
                label: "Đăng Nhập",
                onClick: handleSubmit(onSubmit),
            }}
            link={{
                textDisplay: "Chưa có tài khoản? Đăng kí",
                to: "/sign-up",
            }}>
            <TextFieldHF
                control={control}
                required
                name="email"
                type="email"
                label="Email"
                margin="normal"
            />
            <TextFieldHF
                control={control}
                required
                name="password"
                type="password"
                margin="normal"
                label="Mật khẩu"
            />
        </AuthSection>
    );
};

export default LoginPage;
