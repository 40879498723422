import { BrowserRouter, Route, Routes } from "react-router-dom";

import OverlayLoading from "./components/OverlayLoading";
import ActionSnackbar from "src/components/ActionSnackbar";

import { RoutePage } from "./constants/route";
import useDocumentTitle from "./hooks/useDocumentTittle";
import CollectionsPage from "./pages/CollectionsPage";
import CreateCollectionPage from "./pages/CreateCollectionPage";
import CreateOrderPage from "./pages/CreateOrderPage";
import CreateProductVariantPage from "./pages/CreateProductVariantPage";
import EditCollectionPage from "./pages/EditCollectionPage";
import EditProductPage from "./pages/EditProductPage";
import EditProductVariantPage from "./pages/EditProductVariantPage";
import MenuPage from "./pages/MenuPage";
import SignUpPage from "./pages/SignUpPage";

import "./App.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import MLayout from "src/layouts/MLayout";
import CreateProductPage from "src/pages/CreateProductPage";
import GeneralPage from "src/pages/GeneralPage";
import LoginPage from "src/pages/LoginPage";
import NotFoundPage from "src/pages/NotFoundPage";
import OrdersPage from "src/pages/OrdersPage";
import ProductsPage from "src/pages/ProductsPage";
import GlobalProvider from "src/shares/GlobalProvider";

const queryClient = new QueryClient();

const RoutesChild = () => {
    useDocumentTitle();

    return (
        <GlobalProvider>
            <Routes>
                <Route path="/" element={<MLayout />}>
                    <Route index element={<GeneralPage />} />
                    <Route path={`/${RoutePage.Order}`} element={<OrdersPage />} />
                    <Route path={`/${RoutePage.Order}/create`} element={<CreateOrderPage />} />

                    <Route path={`/${RoutePage.Product}/create`} element={<CreateProductPage />} />
                    <Route path={`/${RoutePage.Product}`} element={<ProductsPage />} />
                    <Route path={`/${RoutePage.Product}/:id/edit`} element={<EditProductPage />} />
                    <Route
                        path={`/${RoutePage.Product}/:id/variants/:variantId/edit`}
                        element={<EditProductVariantPage />}
                    />
                    <Route
                        path={`/${RoutePage.Product}/:id/variants/create`}
                        element={<CreateProductVariantPage />}
                    />

                    <Route path={`/${RoutePage.Collection}`} element={<CollectionsPage />} />
                    <Route
                        path={`/${RoutePage.Collection}/:id/edit`}
                        element={<EditCollectionPage />}
                    />
                    <Route
                        path={`/${RoutePage.Collection}/create`}
                        element={<CreateCollectionPage />}
                    />

                    <Route path={`/${RoutePage.Menu}/edit`} element={<MenuPage />} />
                    <Route path={`/${RoutePage.Menu}/edit`} element={<MenuPage />} />
                </Route>

                <Route path={`/${RoutePage.Login}`} element={<LoginPage />} />
                <Route path={`/${RoutePage.SignUp}`} element={<SignUpPage />} />
                <Route path="*" element={<NotFoundPage />} />
            </Routes>
            <ActionSnackbar />
            <OverlayLoading />
        </GlobalProvider>
    );
};

function App() {
    return (
        <QueryClientProvider client={queryClient}>
            <BrowserRouter>
                <RoutesChild />
            </BrowserRouter>
        </QueryClientProvider>
    );
}

export default App;
