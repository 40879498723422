import { Pagination } from "src/types/paging";
import { ProductResponse } from "src/types/services";

import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    FormControlLabel,
    FormGroup,
    InputAdornment,
    TextField,
    Typography,
} from "@mui/material";

interface ProductSelectionDialogProps {
    open: boolean;
    onClose: () => void;
    onSelected: (checked: boolean, ...selectedProducts: ProductResponse[]) => void;
    products?: ProductResponse[];
    onSearch: (keyword: string) => void;
    selected: Pick<ProductResponse, "id" | "name" | "images">[];
    pagination: Pagination;
}

const ProductSelectionDialog = ({
    open,
    products,
    selected,
    pagination: { onPageChange },
    onSearch,
    onClose,
    onSelected,
}: ProductSelectionDialogProps) => {
    const handleSearch = (value: string) => {
        onSearch(value);
    };

    const handleCloseDialog = () => {
        onSearch("");
        onClose();
    };

    return (
        <Dialog
            open={open}
            onClose={() => handleCloseDialog()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle>Chọn sản phẩm</DialogTitle>
            <DialogContent>
                <Box display={"flex"} px={2}>
                    <FormControlLabel
                        sx={{ mr: 0 }}
                        name="check_all"
                        checked={
                            products &&
                            products.every(({ id }) => selected.map((e) => e.id).includes(id))
                        }
                        onChange={(_, checked) =>
                            onSelected(checked, ...(products as ProductResponse[]))
                        }
                        control={<Checkbox size="small" />}
                        label={""}
                    />
                    <TextField
                        onChange={(event) => handleSearch(event.target.value)}
                        size="small"
                        fullWidth
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchOutlinedIcon fontSize="small" />
                                </InputAdornment>
                            ),
                        }}
                        sx={{ marginBottom: 1 }}
                        variant="outlined"
                    />
                </Box>
                <Divider />
                <FormGroup
                    sx={{
                        height: "415px",
                        width: "550px",
                        overflowY: "auto",
                        flexWrap: "nowrap",
                        paddingX: 2,
                    }}>
                    {products?.length ? (
                        products.map((product) => (
                            <FormControlLabel
                                key={product.id}
                                name="products_ids"
                                checked={Boolean(selected.find((e) => e.id === product.id))}
                                onChange={(_, checked) => onSelected(checked, product)}
                                control={<Checkbox size="small" id={product.id} />}
                                label={product.name}
                                sx={{
                                    "& .MuiFormControlLabel-label": {
                                        fontSize: "14px",
                                    },
                                    borderBottom: "1px solid #e0e0e0",
                                    mr: 0,
                                }}
                            />
                        ))
                    ) : (
                        <Box display={"flex"} textAlign={"center"} justifyContent={"center"}>
                            <Typography
                                variant="caption"
                                fontStyle={"italic"}
                                py={2}
                                alignSelf={"center"}>
                                Không có dữ liệu phù hợp
                            </Typography>
                        </Box>
                    )}
                </FormGroup>

                <Box textAlign={"right"} pt={2} pb={0}>
                    <Button
                        aria-label="previous"
                        color="primary"
                        variant="outlined"
                        onClick={(e) => onPageChange(e, -2)}>
                        <ArrowBackIosNewOutlinedIcon fontSize="inherit" />
                    </Button>
                    <Button
                        aria-label="next"
                        color="primary"
                        variant="outlined"
                        sx={{ marginLeft: 1 }}
                        onClick={(e) => onPageChange(e, -1)}>
                        <ArrowForwardIosOutlinedIcon fontSize="inherit" />
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default ProductSelectionDialog;
