import { createTheme } from "@mui/material";

export default createTheme({
    palette: {
        common: {
            black: "#212121",
        },
        primary: {
            main: "#2962ff",
        },
        secondary: {
            main: "#e3e9ed",
        },
        success: {
            main: "#1eb842",
        },
        error: {
            main: "#c30",
        },
        text: {
            primary: "#212121",
        },
        background: {
            default: "#f5f5f5",
        },
    },
    typography: {
        fontSize: 14,
        button: {
            textTransform: "capitalize",
        },
    },
});

export const HEADER_WIDTH = 64;
