import { Image } from "antd";
import { UseFormReturn } from "react-hook-form";
import { OrderProductVariantsForm, OrderVariantItem } from "src/types/forms";
import { ProductResponse } from "src/types/services";
import { numberToCurrency, urlImageWithEmpty } from "src/util/common";
import { ArrayElement } from "src/util/types";

import DeleteIcon from "@mui/icons-material/Delete";
import { Box, Button, IconButton, Stack, Typography } from "@mui/material";

import AutocompleteHF from "./rhf/AutocompleteHF";
import TextFieldHF from "./rhf/TextFieldHF";

const OrderProductSelectedItem = ({
    fields,
    productIndex,
    product: { name, images, variants },
    control,
    append,
    remove,
}: {
    productIndex: number;
    product: ProductResponse;
    control: UseFormReturn<OrderProductVariantsForm>["control"];
    fields: ArrayElement<OrderProductVariantsForm["orderProductVariants"]>["orderVariants"];
    append: (productIndex: number, variant: OrderVariantItem) => void;
    remove: (productIndex: number, variantIndex: number) => void;
}) => {
    const firstImageProductUrl = images && images[0].url;
    const productVariantOption = variants.map((variant) => ({
        label: variant.attributes.length
            ? variant.attributes.map((va) => va.name).join(" / ")
            : variant.id,
        value: variant.id,
    }));

    const handleAddVariant = () => {
        const defaultOptions = variants.filter(
            (pVariant) => !fields.some((field) => field.variant_id === pVariant.id)
        );
        append(productIndex, {
            variant_id: defaultOptions[0].id,
            quantity: 1,
        });
    };

    const handleDeleteVariant = (index: number) => {
        remove(productIndex, index);
    };

    const getVariantPriceById = (id: string) => {
        const variant = variants.find((v) => v.id === id);
        return variant ? variant.price : 0;
    };

    const productPrice = fields.reduce(
        (pre, cur) => (pre += cur.quantity * getVariantPriceById(cur.variant_id)),
        0
    );

    return (
        <Box
            display={"flex"}
            border={(theme) => `1px solid ${theme.palette.divider}`}
            p={1}
            borderRadius={"4px"}>
            <Box>
                <Image width={60} src={urlImageWithEmpty(firstImageProductUrl)} />
            </Box>
            <Stack px={1} width={"100%"} spacing={0.5}>
                <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    columnGap={1}
                    alignItems="start">
                    <Typography variant="body1" fontWeight={"bold"}>
                        {name}
                    </Typography>
                    <Typography variant="body1" fontWeight={"bold"}>
                        {numberToCurrency(productPrice)}
                    </Typography>
                </Box>
                {fields.map((field, index) => {
                    return (
                        <Box
                            key={field.variant_id}
                            display={"flex"}
                            alignItems={"center"}
                            columnGap={0.5}
                            px={1}>
                            <Typography variant="body2" whiteSpace={"nowrap"}>
                                + Biến thể:
                            </Typography>
                            <AutocompleteHF
                                formControlProps={{
                                    control,
                                    name: `orderProductVariants.${productIndex}.orderVariants.${index}.variant_id`,
                                }}
                                fullWidth={false}
                                placeholder="Biến thể"
                                disableClearable
                                options={productVariantOption}
                                getOptionDisabled={(option) =>
                                    fields.some((field) => field.variant_id === option.value)
                                }
                                sx={{
                                    width: 150,
                                }}
                            />
                            <Typography variant="body2">x</Typography>
                            <TextFieldHF
                                control={control}
                                name={`orderProductVariants.${productIndex}.orderVariants.${index}.quantity`}
                                type="number"
                                inputProps={{ min: 0 }}
                                sx={{
                                    width: 60,
                                }}
                            />
                            <Typography variant="body2">=</Typography>
                            <Typography variant="body2">
                                {numberToCurrency(
                                    field.quantity * getVariantPriceById(field.variant_id)
                                )}
                            </Typography>
                            <IconButton
                                size="small"
                                onClick={() => handleDeleteVariant(index)}
                                disabled={fields.length <= 1}>
                                <DeleteIcon />
                            </IconButton>
                        </Box>
                    );
                })}
                <Button
                    variant="text"
                    size="small"
                    disabled={fields.length === productVariantOption.length}
                    onClick={handleAddVariant}>
                    + Thêm
                </Button>
            </Stack>
        </Box>
    );
};

export default OrderProductSelectedItem;
