import { ProductForm } from "src/types/forms";
import { postData } from "src/util/service";

import { useMutation } from "@tanstack/react-query";
import useShowSnackbar from "src/hooks/useShowSnackbar";

const useCreateProduct = ({ onSuccess }: { onSuccess: (data: ProductForm) => void }) => {
    const showSnackbar = useShowSnackbar();

    return useMutation({
        mutationKey: ["create-product"],
        mutationFn: (data: ProductForm) => postData<ProductForm>("/products/with-admin", data),
        onSuccess(data) {
            showSnackbar("Tạo nhóm phẩm mới thành công!", "success");
            onSuccess(data.data);
        },
        onError() {
            showSnackbar("Tạo nhóm phẩm mới thất bại!", "error");
        },
    });
};

export default useCreateProduct;
