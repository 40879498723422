import Joi from "joi";
import { CollectionForm, LoginForm, OrderForm, ProductForm, SignUpForm } from "src/types/forms";

export const loginValidateSchema = Joi.object<LoginForm>({
    email: Joi.string()
        .required()
        .email({ minDomainSegments: 2, tlds: { allow: ["com", "net", "vn"] } })
        .messages({
            "any.required": "Email không được để trống",
            "string.empty": "Email không được để trống",
            "string.base": "Email không được để trống",
            "string.email": "Email chưa đúng định dạng (.com, .net, .vn)",
        }),
    password: Joi.string().required().min(6).messages({
        "any.required": "Mật khẩu không được để trống",
        "string.empty": "Mật khẩu không được để trống",
        "string.base": "Mật khẩu không được để trống",
        "string.min": "Mật khẩu nên được đặt từ 6 kí tự trở lên",
    }),
});

export const signUpValidateSchema = Joi.object<SignUpForm>({
    name: Joi.string().required().messages({
        "any.required": "Tên không được để trống",
        "string.empty": "Tên không được để trống",
        "string.base": "Tên không được để trống",
    }),
    email: Joi.string()
        .required()
        .email({ minDomainSegments: 2, tlds: { allow: ["com", "net", "vn"] } })
        .messages({
            "any.required": "Email không được để trống",
            "string.empty": "Email không được để trống",
            "string.base": "Email không được để trống",
            "string.email": "Email chưa đúng định dạng (.com, .net, .vn)",
        }),
    password: Joi.string().required().min(6).messages({
        "any.required": "Mật khẩu không được để trống",
        "string.empty": "Mật khẩu không được để trống",
        "string.base": "Mật khẩu không được để trống",
        "string.min": "Mật khẩu nên được đặt từ 6 kí tự trở lên",
    }),
    confirm_password: Joi.string().required().valid(Joi.ref("password")).messages({
        "any.required": "Mật khẩu nhập lại không được để trống",
        "string.empty": "Mật khẩu nhập lại không được để trống",
        "string.base": "Mật khẩu nhập lại không được để trống",
        "string.min": "Mật khẩu nhập lại nên được đặt từ 6 kí tự trở lên",
        "any.only": "Mật khẩu nhập lại chưa trùng khớp",
    }),
});

export const mutateCollectionValidateSchema = Joi.object<CollectionForm>({
    title: Joi.string().required().messages({
        "any.required": "Tên nhóm sản phẩm không được để trống",
        "string.empty": "Tên nhóm sản phẩm không được để trống",
        "string.base": "Tên nhóm sản phẩm không được để trống",
    }),
    product_ids: Joi.allow(),
    description: Joi.allow(),
    image_url: Joi.allow(),
    is_visible: Joi.allow(),
});

export const mutateProductValidateSchema = Joi.object<ProductForm>({
    name: Joi.string().required().messages({
        "any.required": "Tên sản phẩm không được để trống",
        "string.empty": "Tên sản phẩm không được để trống",
        "string.base": "Tên sản phẩm không được để trống",
    }),
    category_id: Joi.string().required().messages({
        "any.required": "Loại sản phẩm không được để trống",
        "string.empty": "Loại sản phẩm không được để trống",
        "string.base": "Loại sản phẩm không được để trống",
    }),
    vendor_id: Joi.string().required().messages({
        "any.required": "Nhà cung cấp sản phẩm không được để trống",
        "string.empty": "Nhà cung cấp sản phẩm không được để trống",
        "string.base": "Nhà cung cấp sản phẩm không được để trống",
    }),
    description: Joi.allow(),
    collection_ids: Joi.allow(),
    images: Joi.allow(),
    is_visible: Joi.allow(),
    quote: Joi.allow(),
    type: Joi.allow(),
    variants: Joi.allow(),
});

export const mutateOrderValidateSchema = Joi.object<OrderForm>({
    first_name: Joi.string().required().messages({
        "any.required": "Tên không được để trống",
        "string.empty": "Tên không được để trống",
        "string.base": "Tên không được để trống",
    }),
    last_name: Joi.string().required().messages({
        "any.required": "Họ không được để trống",
        "string.empty": "Họ không được để trống",
        "string.base": "Họ không được để trống",
    }),
    address: Joi.string().required().messages({
        "any.required": "Địa chỉ không được để trống",
        "string.empty": "Địa chỉ không được để trống",
        "string.base": "Địa chỉ không được để trống",
    }),
    phone_number: Joi.string()
        .required()
        .regex(/([\\+84|84|0]+(3|5|7|8|9|1[2|6|8|9]))+([0-9]{8})\b/)
        .messages({
            "any.required": "SĐT không được để trống",
            "string.empty": "SĐT không được để trống",
            "string.base": "SĐT không được để trống",
            "string.pattern.base": "SĐT không hợp lệ",
        }),
    variant_orders: Joi.allow(),
    note: Joi.allow(),
    additional_price: Joi.allow(),
    final_price: Joi.allow(),
    payment_method: Joi.allow(),
    shipping_price: Joi.allow(),
});
