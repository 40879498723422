import { useState } from "react";

import { PAGE_COUNT } from "src/constants/pagination";

import { TablePaginationProps } from "@mui/material";

import useDebounce from "src/hooks/useDebounce";

const useGetQueryUtil = ({ initialOffset }: { initialOffset?: number } = {}) => {
    const [page, setPage] = useState<number>(0);
    const [pageCount, setPageCount] = useState<number>(initialOffset ?? PAGE_COUNT[0]);
    const [name, setName] = useState<string>("");
    const [enabled, setEnabled] = useState<boolean>(true);

    const nameDebounce = useDebounce(name, 500);

    const onSearch = (keyword: string) => {
        setEnabled(false);
        setPage(0);
        setName(keyword);
        setEnabled(true);
    };

    const onReset = () => {
        setEnabled(false);
        setName("");
        setPage(0);
        setEnabled(true);
    };

    const changePaging: TablePaginationProps["onPageChange"] = (_, newPage) => {
        setEnabled(true);

        switch (newPage) {
            case -1:
                setPage((previousPage) => (previousPage += 1));
                break;

            case -2:
                if (page <= 0) break;
                setPage((previousPage) => (previousPage -= 1));
                break;

            default:
                setPage(newPage);
                break;
        }
    };

    const changePageOption: TablePaginationProps["onRowsPerPageChange"] = (e) => {
        setPageCount(parseInt(e.target.value));
        setPage(0);
    };

    return {
        page,
        pageCount,
        nameDebounce,
        enabled,
        onSearch,
        onReset,
        changePaging,
        changePageOption,
    };
};

export default useGetQueryUtil;
