import { useState } from "react";

import { useFieldArray, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { mutateOrderValidateSchema } from "src/types/form-validations";
import { OrderForm, OrderProductVariantsForm, OrderVariantItem } from "src/types/forms";
import { ProductResponse } from "src/types/services";
import { numberToCurrency } from "src/util/common";

import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import ActionButtonSection from "src/components/ActionButtonSection";
import ProductSelectionDialog from "src/components/dialogs/ProductSelectionDialog";
import OrderProductSelectedItem from "src/components/OrderProductSelectedItem";
import PageWrapper from "src/components/PageWrapper";
import { PaperContentSection } from "src/components/PaperContentSection";
import TextFieldHF from "src/components/rhf/TextFieldHF";

import { joiResolver } from "@hookform/resolvers/joi";
import useCreateOrder from "src/hooks/useCreateOrder";
import useProducts from "src/hooks/useProducts";
import useShowOverlayLoading from "src/hooks/useShowOverlayLoading";
import useShowSnackbar from "src/hooks/useShowSnackbar";

const CreateOrderPage = () => {
    const showSnackBar = useShowSnackbar();
    const navigate = useNavigate();
    const showLoading = useShowOverlayLoading();

    const { mutate: onCreate } = useCreateOrder({
        onSuccess: () => {
            showLoading(false);

            navigate("/orders");
        },
    });
    const { products, pagination, onSearch, onReset } = useProducts({
        onSuccess: (data) => {},
    });

    const [productsSelected, setProductsSelected] = useState<ProductResponse[]>([]);
    const [isShowProductSelectionDialog, setIsShowProductSelectionDialog] =
        useState<boolean>(false);

    const handleProductSelection = (checked: boolean, ...selectedProducts: ProductResponse[]) => {
        if (!selectedProducts.length) return;

        switch (checked) {
            case true:
                setProductsSelected((preValue) => [...preValue, ...selectedProducts]);

                selectedProducts.forEach(({ id, variants }) => {
                    const firstVariant = variants.at(0);

                    appendOrderProduct({
                        productId: id,
                        orderVariants: [
                            {
                                variant_id: firstVariant?.id || `error_variant_id_product_${id}`,
                                quantity: 1,
                            },
                        ],
                    });
                });

                break;

            default:
                selectedProducts.forEach(({ id }) => {
                    setProductsSelected((preValue) => [...preValue.filter((p) => p.id !== id)]);

                    const deletedProductInFormIndex = orderProductVariantFieldsWatch.findIndex(
                        (orderProductField) => orderProductField.productId === id
                    );

                    if (deletedProductInFormIndex !== -1) {
                        removeOrderProduct(deletedProductInFormIndex);
                    }
                });
                break;
        }
    };

    const handleAddProduct = () => {
        setIsShowProductSelectionDialog(true);
    };

    const { control, handleSubmit } = useForm<OrderForm>({
        defaultValues: {
            variant_orders: [],
            phone_number: "",
            last_name: "",
            first_name: "",
            address: "",
            payment_method: "COD",
            additional_price: 0,
            shipping_price: 0,
            final_price: 0,
            note: "",
        },
        resolver: joiResolver(mutateOrderValidateSchema),
    });

    const onSubmit = (data: OrderForm) => {
        const orderVariants = orderProductVariantFieldsWatch.reduce(
            (pre, cur) => [...pre, ...cur.orderVariants],
            [] as OrderVariantItem[]
        );

        if (orderVariants.length === 0) {
            showSnackBar("Đơn hàng phải có ít nhất một biến thể của một sản phẩm", "error");
        } else {
            const dataSave: OrderForm = {
                ...data,
                variant_orders: orderVariants,
                final_price: totalPrice,
            };

            showLoading(true);
            onCreate(dataSave);
        }
    };

    const { control: controlOrderProductVariants, watch } = useForm<OrderProductVariantsForm>({
        defaultValues: {
            orderProductVariants: [],
        },
    });

    const {
        fields: orderProductVariantFields,
        append: appendOrderProduct,
        remove: removeOrderProduct,
        update,
    } = useFieldArray({
        control: controlOrderProductVariants,
        name: "orderProductVariants",
    });

    const orderProductVariantFieldsWatch = watch().orderProductVariants;

    const appendOrderProductVariant = (
        indexOrderProduct: number,
        productVariant: OrderVariantItem
    ) => {
        const currentOrderProduct = orderProductVariantFieldsWatch[indexOrderProduct];
        update(indexOrderProduct, {
            ...currentOrderProduct,
            orderVariants: [...currentOrderProduct.orderVariants, productVariant],
        });
    };

    const removeOrderProductVariant = (
        indexOrderProduct: number,
        indexOrderProductVariant: number
    ) => {
        const currentOrderProduct = orderProductVariantFieldsWatch[indexOrderProduct];
        update(indexOrderProduct, {
            ...currentOrderProduct,
            orderVariants: currentOrderProduct.orderVariants.filter(
                (_, cIndex) => cIndex !== indexOrderProductVariant
            ),
        });
    };

    const tempTotalProductsPrice = orderProductVariantFieldsWatch.reduce(
        (pre, cur) =>
            (pre += cur.orderVariants.reduce((ovp, ovc) => {
                const selectedProduct = productsSelected.find(
                    (pSelected) => pSelected.id === cur.productId
                );

                if (!selectedProduct) return ovp;

                const getVariantPriceById = (id: string) => {
                    const variant = selectedProduct.variants.find((v) => v.id === id);
                    return variant ? variant.price : 0;
                };

                return (ovp += ovc.quantity * getVariantPriceById(ovc.variant_id));
            }, 0)),
        0
    );
    const totalProduct = orderProductVariantFieldsWatch.length;
    const totalProductVariant = orderProductVariantFieldsWatch.reduce(
        (pre, cur) => (pre += cur.orderVariants.length),
        0
    );
    const totalPrice = tempTotalProductsPrice - 0;

    return (
        <>
            <PageWrapper
                title={"Tạo đơn hàng"}
                actionArea={
                    <ActionButtonSection actionOnClick={handleSubmit(onSubmit)} backTo="/orders" />
                }>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={12} lg={4}>
                        <PaperContentSection title="Chi tiết khách hàng" topSection>
                            <Grid container spacing={1.5} p={1}>
                                <Grid item xs={6}>
                                    <TextFieldHF
                                        control={control}
                                        name="last_name"
                                        labelOverride="Họ"
                                        placeholder="Họ"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextFieldHF
                                        control={control}
                                        name="first_name"
                                        labelOverride="Tên"
                                        placeholder="Tên"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextFieldHF
                                        control={control}
                                        name="address"
                                        labelOverride="Địa chỉ"
                                        placeholder="Địa chỉ"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextFieldHF
                                        control={control}
                                        name="phone_number"
                                        labelOverride="Số điện thoại"
                                        placeholder="Số điện thoại"
                                    />
                                </Grid>
                            </Grid>
                        </PaperContentSection>

                        <PaperContentSection title="Chi tiết đơn hàng">
                            <Grid container spacing={2} p={1}>
                                <Grid item xs={6}>
                                    <Typography variant="body2">Loại thanh toán:</Typography>
                                </Grid>
                                <Grid item xs={6} textAlign={"end"}>
                                    <Typography variant="body2">C.O.D</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body2">Số lượng sản phẩm:</Typography>
                                </Grid>
                                <Grid item xs={6} textAlign={"end"}>
                                    <Typography variant="body2">{totalProduct} (đơn vị)</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body2">Số lượng biến thể:</Typography>
                                </Grid>
                                <Grid item xs={6} textAlign={"end"}>
                                    <Typography variant="body2">
                                        {totalProductVariant} (đơn vị)
                                    </Typography>
                                </Grid>

                                <Grid item xs={6}>
                                    <Typography variant="body2">Tạm tính:</Typography>
                                </Grid>
                                <Grid item xs={6} textAlign={"end"}>
                                    <Typography variant="body2">
                                        {numberToCurrency(tempTotalProductsPrice)}
                                    </Typography>
                                </Grid>

                                <Grid item xs={6}>
                                    <Typography variant="body2">Khuyến mãi:</Typography>
                                </Grid>
                                <Grid item xs={6} textAlign={"end"}>
                                    <Typography variant="body2">- {numberToCurrency(0)}</Typography>
                                </Grid>

                                <Grid item xs={12}>
                                    <TextFieldHF
                                        control={control}
                                        name="note"
                                        fullWidth
                                        placeholder="Ghi chú"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider />
                                </Grid>

                                <Grid item xs={6}>
                                    <Typography variant="body1" fontWeight={"bold"}>
                                        Tổng:
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} textAlign={"end"}>
                                    <Typography variant="body1" fontWeight={"bold"}>
                                        {numberToCurrency(totalPrice)}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </PaperContentSection>
                    </Grid>
                    <Grid item xs={12} md={12} lg={8}>
                        <PaperContentSection
                            topSection
                            title="Sản phẩm"
                            action={{
                                onAction: handleAddProduct,
                                title: "Thêm sản phẩm",
                                icon: <AddCircleOutlinedIcon />,
                            }}>
                            <Grid container spacing={2} p={2}>
                                <Grid item xs={12}>
                                    <Stack spacing={1}>
                                        {orderProductVariantFields.length ? (
                                            orderProductVariantFields.map(
                                                (orderProductVariant, index) => {
                                                    const selectedProduct = productsSelected.find(
                                                        (p) =>
                                                            p.id === orderProductVariant.productId
                                                    );

                                                    if (!selectedProduct) return null;

                                                    return (
                                                        <OrderProductSelectedItem
                                                            control={controlOrderProductVariants}
                                                            productIndex={index}
                                                            product={selectedProduct}
                                                            key={orderProductVariant.id}
                                                            fields={
                                                                orderProductVariantFieldsWatch[
                                                                    index
                                                                ].orderVariants
                                                            }
                                                            append={appendOrderProductVariant}
                                                            remove={removeOrderProductVariant}
                                                        />
                                                    );
                                                }
                                            )
                                        ) : (
                                            <Box>
                                                <Typography variant="body2">
                                                    Chưa có sản phẩm
                                                </Typography>
                                            </Box>
                                        )}
                                    </Stack>
                                </Grid>
                            </Grid>
                        </PaperContentSection>
                    </Grid>
                </Grid>
            </PageWrapper>

            <ProductSelectionDialog
                open={isShowProductSelectionDialog}
                products={products}
                selected={productsSelected}
                onSearch={onSearch}
                pagination={pagination}
                onSelected={handleProductSelection}
                onClose={() => {
                    setIsShowProductSelectionDialog(false);
                    onReset();
                }}
            />
        </>
    );
};

export default CreateOrderPage;
