import { useState } from "react";

import { Navigate, Outlet } from "react-router-dom";

import { Box, Container } from "@mui/material";

import useAdminProfile from "src/hooks/useAdminProfile";
import MHeader from "src/layouts/MHeader";
import Sidebar from "src/layouts/Sidebar";

const MLayout = () => {
    const { isLogin } = useAdminProfile();

    const [mobileOpen, setMobileOpen] = useState(false);

    const handleDrawerToggle = (toggle: boolean = false) => {
        setMobileOpen(toggle);
    };

    if (!isLogin) {
        return <Navigate to="/login" replace />;
    }

    return (
        <Box sx={{ display: "flex" }}>
            <Sidebar handleDrawerToggle={handleDrawerToggle} mobileOpen={mobileOpen} />

            <Container maxWidth="lg">
                <MHeader handleDrawerToggle={handleDrawerToggle} />
                <Outlet />
            </Container>
        </Box>
    );
};

export default MLayout;
