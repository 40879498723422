import { Fragment, useState } from "react";

import { Controller, useFieldArray, UseFieldArrayReturn, useForm } from "react-hook-form";
import { VariantItemsForm } from "src/types/forms";
import { AttributeValuesField } from "src/types/views";
import { generateVariants } from "src/util/variant-product";

import DeleteIcon from "@mui/icons-material/Delete";
import {
    Alert,
    Autocomplete,
    Box,
    Button,
    Grid,
    IconButton,
    TextField,
    Typography,
} from "@mui/material";

import { PaperContentSection } from "./PaperContentSection";
import AutocompleteHF from "./rhf/AutocompleteHF";
import VariantSelections from "./VariantSelections";

import useAttributes from "src/hooks/useAttributes";

const CreateVariantSection = ({
    fields: variantFields,
    replace: replaceVariants,
    update: updateVariants,
    defaultAttributeValues = [{ attributeId: "", values: [] }],
}: { defaultAttributeValues?: AttributeValuesField["fields"] } & Pick<
    UseFieldArrayReturn<VariantItemsForm, "variantItems">,
    "update" | "fields" | "replace"
>) => {
    const attributesData = useAttributes();
    const [attributeEditable, setAttributeEditable] = useState<boolean>(true);

    const { control, getValues } = useForm<AttributeValuesField>({
        defaultValues: {
            fields: defaultAttributeValues,
        },
    });
    const { fields, append, remove } = useFieldArray({
        control,
        name: "fields",
    });

    const handleAddAttribute = () => {
        append({ attributeId: "", values: [] });
    };

    const onDeleteVariant = (index: number) => {
        remove(index);
    };

    const handleGenerateVariants = () => {
        setAttributeEditable(false);
        const generatedNewVariantFields = generateVariants(
            getValues("fields").filter((attributeValue) => attributeValue.values.length),
            attributesData
        );

        const variantFieldsShouldReplace = generatedNewVariantFields.map((newVariantField) => {
            const currentVariantField = variantFields.find(
                ({ attributeMappingId }) =>
                    attributeMappingId === newVariantField.attributeMappingId
            );

            if (currentVariantField) {
                return currentVariantField;
            } else {
                return newVariantField;
            }
        });

        replaceVariants(variantFieldsShouldReplace);
    };

    return (
        <>
            <Box mt={3}>
                <Alert severity="info">
                    Việc thay đổi thuộc tính có thể làm thay đổi những dữ liệu của biến thể
                </Alert>
            </Box>
            <PaperContentSection title="Thuộc tính" sx={{ mt: 1 }}>
                <Grid container spacing={1} p={2}>
                    {!fields.length ? (
                        <Grid item xs={12}>
                            <Box display={"flex"} justifyContent={"center"}>
                                Chưa có dữ liệu
                            </Box>
                        </Grid>
                    ) : (
                        <>
                            <Grid item xs={6}>
                                <Typography variant="body2" fontWeight={"bold"}>
                                    Thuộc tính
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="body2" fontWeight={"bold"}>
                                    Giá trị
                                </Typography>
                            </Grid>
                        </>
                    )}
                    {fields.map((fieldData, index) => (
                        <Fragment key={fieldData.id}>
                            <Grid item xs={6}>
                                <AutocompleteHF
                                    formControlProps={{
                                        control,
                                        name: `fields.${index}.attributeId`,
                                    }}
                                    placeholder="Thuộc tính"
                                    options={attributesData.map((option) => ({
                                        label: option.name,
                                        value: option.id,
                                    }))}
                                    getOptionDisabled={(option) =>
                                        getValues("fields").some(
                                            (field) => field.attributeId === option.value
                                        )
                                    }
                                    disabled={!attributeEditable}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Box display={"flex"}>
                                    <Controller
                                        control={control}
                                        name={`fields.${index}.values`}
                                        render={({ field: { onChange, ...restFieldProps } }) => (
                                            <Autocomplete<string, true, true, true>
                                                {...restFieldProps}
                                                disabled={!attributeEditable}
                                                options={[]}
                                                freeSolo
                                                multiple
                                                fullWidth
                                                onChange={(_, values) => onChange(values)}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        size="small"
                                                        placeholder="Giá trị"
                                                    />
                                                )}
                                            />
                                        )}
                                    />
                                    <IconButton
                                        color="error"
                                        disabled={fields.length <= 1 || !attributeEditable}
                                        onClick={() => onDeleteVariant(index)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </Box>
                            </Grid>
                        </Fragment>
                    ))}
                </Grid>
                <Box display={"flex"} justifyContent={"space-between"} px={2}>
                    <Button
                        variant="contained"
                        color="info"
                        onClick={() => setAttributeEditable(true)}
                        disabled={attributeEditable}>
                        Thay đổi
                    </Button>
                    <Button
                        variant="contained"
                        onClick={handleAddAttribute}
                        disabled={fields.length >= 3 || !attributeEditable}>
                        Thêm thuộc tính
                    </Button>
                    <Button
                        variant="contained"
                        color="info"
                        onClick={handleGenerateVariants}
                        disabled={!attributeEditable}>
                        Xong
                    </Button>
                </Box>
            </PaperContentSection>

            <VariantSelections fields={variantFields} update={updateVariants} />
        </>
    );
};

export default CreateVariantSection;
