import { MouseEvent, useState } from "react";

import FilterListOutlined from "@mui/icons-material/FilterListOutlined";
import {
    Box,
    Button,
    FormControl,
    MenuItem,
    Popover,
    Select,
    SelectChangeEvent,
    Typography,
} from "@mui/material";
import { PaperCustom } from "src/components/PaperCustom";

const ProductFilterPopover = () => {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [age, setAge] = useState("");

    const handleChange = (event: SelectChangeEvent) => {
        setAge(event.target.value);
    };

    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    return (
        <>
            <Button
                aria-describedby={id}
                variant="outlined"
                onClick={handleClick}
                startIcon={<FilterListOutlined />}>
                Thêm Điều kiện lọc
            </Button>

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                sx={{
                    width: "100%",
                }}>
                <PaperCustom
                    sx={{
                        width: "270px",
                    }}>
                    <Typography variant="body2" pb={1}>
                        Hiển thị tất cả sản phẩm theo:
                    </Typography>
                    <FormControl size="small">
                        <Select value={age} onChange={handleChange} sx={{ marginBottom: "10px" }}>
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            <MenuItem value={10}>Ten</MenuItem>
                            <MenuItem value={20}>Twenty</MenuItem>
                            <MenuItem value={30}>Thirty</MenuItem>
                        </Select>
                        <Select value={age} onChange={handleChange} sx={{ marginBottom: "10px" }}>
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            <MenuItem value={10}>Ten</MenuItem>
                            <MenuItem value={20}>Twenty</MenuItem>
                            <MenuItem value={30}>Thirty</MenuItem>
                        </Select>
                        <Select value={age} onChange={handleChange} sx={{ marginBottom: "10px" }}>
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            <MenuItem value={10}>Ten</MenuItem>
                            <MenuItem value={20}>Twenty</MenuItem>
                            <MenuItem value={30}>Thirty</MenuItem>
                        </Select>
                    </FormControl>
                    <Box display={"flex"} justifyContent={"space-between"}>
                        <Button variant="contained" color="secondary">
                            Hủy
                        </Button>
                        <Button variant="contained">Thêm điều kiện lọc</Button>
                    </Box>
                </PaperCustom>
            </Popover>
        </>
    );
};

export default ProductFilterPopover;
