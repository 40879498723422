import axios from "axios";

import useShowSnackbar from "./useShowSnackbar";

import { useMutation } from "@tanstack/react-query";

const useUploadImage = () => {
    const showSnackbar = useShowSnackbar();

    return useMutation({
        mutationKey: ["upload-image"],
        mutationFn: (image: any) =>
            axios.post(
                `${process.env.REACT_APP_CLOUDINARY_API_END_POINT}/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/image/upload`,
                {
                    file: image,
                    upload_preset: "ml_default",
                    cloud_name: process.env.REACT_APP_CLOUDINARY_CLOUD_NAME,
                },
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            ),
        onError() {
            showSnackbar("Cập nhật hình ảnh thất bại!", "error");
        },
    });
};

export default useUploadImage;
