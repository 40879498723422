export const urlImageWithEmpty = (url?: string | null) => {
    return url || "/images/nothing-image.png";
};

export const numberToCurrency = (price: number) => {
    return price.toLocaleString("vi-VN", {
        style: "currency",
        currency: "VND",
    });
};

export const convertToDate = (dateString: string) => {
    //  Convert a "dd/MM/yyyy" string into a Date object
    let dat = new Date(dateString);
    return dat.toLocaleDateString("vi-VN");
};
