import { SideBarMenuItemProps } from "src/types/views";

import AutoGraphOutlinedIcon from "@mui/icons-material/AutoGraphOutlined";
import DifferenceOutlinedIcon from "@mui/icons-material/DifferenceOutlined";
import InsertChartOutlinedSharpIcon from "@mui/icons-material/InsertChartOutlinedSharp";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import RedeemOutlinedIcon from "@mui/icons-material/RedeemOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import WidgetsOutlinedIcon from "@mui/icons-material/WidgetsOutlined";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Toolbar from "@mui/material/Toolbar";

import SidebarItem from "src/layouts/SidebarItem";

const drawerWidth = 240;

const menuItems: SideBarMenuItemProps[] = [
    {
        label: "Tổng Quan",
        icon: <AutoGraphOutlinedIcon />,
        path: "/",
        roles: [],
    },
    {
        label: "Đơn Hàng",
        icon: <ShoppingCartOutlinedIcon />,
        path: "/orders",
        roles: [],
    },
    {
        label: "Sản Phẩm",
        icon: <LocalOfferOutlinedIcon />,
        path: "/products",
        roles: [],
    },
    {
        label: "Nhóm sản phẩm",
        icon: <DifferenceOutlinedIcon />,
        path: "/collections",
        roles: [],
    },
    {
        label: "Danh Mục",
        icon: <WidgetsOutlinedIcon />,
        path: "/menus/edit",
        roles: [],
    },
    {
        label: "Khuyến mãi",
        icon: <RedeemOutlinedIcon />,
        path: "/promotions",
        roles: [],
    },
    {
        label: "Báo Cáo",
        icon: <InsertChartOutlinedSharpIcon />,
        path: "/reports",
        roles: [],
    },
];

const Sidebar = ({
    mobileOpen,
    handleDrawerToggle,
}: {
    mobileOpen: boolean;
    handleDrawerToggle: (toggle: boolean) => void;
}) => {
    const drawer = (
        <Box sx={{ minHeight: "100%", background: "#0d3064", overflow: "hidden" }}>
            <Toolbar />
            <List>
                {menuItems.map(({ label, icon, path }, index) => (
                    <SidebarItem
                        key={"sidebar-item" + index}
                        icon={icon}
                        label={label}
                        path={path}
                        handleItemButtonClick={() => handleDrawerToggle(false)}
                    />
                ))}
            </List>
        </Box>
    );

    return (
        <Box component="nav" sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}>
            {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
            <Drawer
                variant="temporary"
                open={mobileOpen}
                onClose={() => handleDrawerToggle(false)}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    display: { sm: "block", md: "none" },
                    "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
                }}>
                {drawer}
            </Drawer>
            <Drawer
                variant="permanent"
                sx={{
                    display: { xs: "none", sm: "none", md: "block" },
                    "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
                }}
                open>
                {drawer}
            </Drawer>
        </Box>
    );
};

export default Sidebar;
